import React from 'react';
import Popup from "../components/Popup";
import TextField from "../components/form/TextField";
import Select from "../components/form/Select";
import Modal from "../components/Modal";
import Moment from "react-moment";
import moment from "moment";
import {Auth} from "../components/Auth";
import Access from "../components/Access";
import {apiRequest} from "../components/Globals";
import FittingService from "../service/FittingService";
import {Fitting} from "../components/form/model/Fitting";
import {BOOKING_STATUS, FIT_TYPE_NUM, MEASUREMENTS} from "../config/Globals";
import {notify} from "../components/Notify";
import Tabs from "../components/form/Tabs";
import Logs from "./calendar/Logs";
import ReactDatePicker from "react-datepicker";
import CheckBox from "../components/form/CheckBox";
import BoxGroup from "../components/form/BoxGroup";
// import RadioBox from "../components/form/RadioBox";

export default class Fittings extends React.Component {
    state = {
        loaded: false,
        showLoadMore: true,
        logs: [],
        startDate: null,
        endDate: null,
        pending: false,
        filterItems: [],
        isJpList: false
    };

    fittingService: FittingService = new FittingService(() => {
        this.setState({})
    });
    filterValue: null;

    modalStatusRep = {
        show: false
    };

    modalStatusPdf = {
        show: false
    };

    modalStatusDates = {
        show: false
    };

    dispatchModalStatus = {
        show: false
    };

    selectedFitting: Fitting = null;
    auth: Auth = new Auth();
    products: {
        id: number,
        product: string,
        quantity: number
    }[] = [];

    modalCancelConfirm = {
        show: false
    };

    selectedCancelId = null;

    initialStartDate = 'from';
    initialEndDate = 'to';

    constructor(props) {
        super(props);

        this.refPopup = React.createRef();

        this.doShowRepModal = this.doShowRepModal.bind(this);
        this.doShowPdfModal = this.doShowPdfModal.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.loadComments = this.loadComments.bind(this);
        this.loadProducts = this.loadProducts.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);

        this.chooseDatesFromTo = this.chooseDatesFromTo.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
        this.downloadDispatchPDF = this.downloadDispatchPDF.bind(this);
        // default search all statuses
        this.fittingService.searchParams.status = [];

        this.auth.load();
    }

    doShowRepModal(id: number) {
        this.modalStatusRep.show = true;
        this.selectedFitting = this.fittingService.getOne(id);
        this.loadProducts(id);

        this.setState({});
    }

    doShowPdfModal() {
        this.modalStatusPdf.show = true;
        this.setState({});
    }


    componentDidMount(): void {
        let search = {};

        if (!Access.isAllowed('Fitter', 'fullList')) {
            search.repId = this.auth.userId;
        }

        this.fittingService.setSearchParams(search);
    }

    onChangeSearch(event) {
        let search = {
            search: event.target.value
        };

        if (!Access.isAllowed('Fitter', 'fullList')) {
            search.repId = this.auth.userId;
        }

        this.fittingService.setSearchParams(search);
    }

    loadMore() {
        this.fittingService.loadMore();
    }

    onChangeFilter(event) {
        let params = {
            status: []
        };

        switch (event.target.value) {
            case 'all':
                params.dateFrom = null;
                params.dateTo = null;

                break;

            case 'today':
                params.dateFrom = moment(new Date()).format('YYYY-MM-DD');
                params.dateTo = params.dateFrom;
                params.status = [1];

                break;

            case 'future':
                params.dateFrom = moment(new Date(), 'YYYY-MM-DD hh:mm:ss');
                params.dateTo = null;
                params.status = [1];
                break;

            case 'canceled':
                params.status = [2];
                break;

            default:
                const tp = event.target.value.split('_');
                params.fit_type = tp[1];
        }

        if (!Access.isAllowed('MeasureAndQuote', 'fullList')) {
            params.repId = this.auth.userId;
        }

        this.fittingService.setSearchParams(params);
    }

    loadComments(id: number) {
        return;

        if (!id) {
            this.setState({logs: []});
            return;
        }

        apiRequest('/bs/booking/comment-list', {
            'bookingId': id
        })
            .then(response => {
                this.setState({logs: response && response.custom ? response.custom : []})
            });

    }

    getTypeText(type: string) {
        switch (type) {
            case '1':
                return 'Check measure';
            case '2':
                return 'Service call';

            case '3':
                return 'Fitting';

            case '4':
                return 'JP';
        }

        return '';
    }

    loadProducts(fittingId: number) {
        this.products = [];

        apiRequest('/bs/fitting/product-list', {
            'id': fittingId
        })
            .then(response => {
                if (!response || !response.custom) {
                    return;
                }

                this.products = response.custom;
                this.setState({})
            });

    }

    onDelete() {

        apiRequest('/bs/fitting/change-status', {
            id: this.selectedCancelId,
            status: BOOKING_STATUS.canceled
        })
            .then(() => {
                notify({
                    text: 'Fitting has been cancelled.'
                });

                this.fittingService
                    .resetPagination()
                    .loadList();

            })
            .catch(response => {
                // this.onLoading(false);
                notify({
                    text: response.message
                })
            });

        this.modalCancelConfirm.show = false;
        this.setState({});

    }

    chooseDatesFromTo(date, dd) {
        this.initialStartDate = moment(date[0]).format('DD MMMM YYYY');
        this.initialEndDate = date[1] ? moment(date[1]).format('DD MMMM YYYY') : '';

        this.setState({
            startDate: date[0],
            endDate: date[1],
        });
    }

    doShowDatesModal() {
        this.modalStatusDates.show = true;
        this.setState({filterItems: []});
    }

    toggleDispatchModal(isOpen: boolean, isJpList: boolean) {
        this.dispatchModalStatus.show = isOpen;
        this.setState({isJpList});
    }

    downloadReport() {
        this.setState({pending: true});

        let payload = {
            filteredItems: this.state.filterItems,
            dateFrom: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
            dateTo: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null,
        };

        this.fittingService.downloadCsv(payload, () => this.setState({pending: false}))
    }

    downloadDispatchPDF() {
        this.setState({pending: true});

        let dates = {
            dateFrom: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
            dateTo: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null,
            measuring: null,
            fit_type: null,
        };

        if (this.state.isJpList) {
            // dates.measuring = MEASUREMENTS.Commercial;
            dates.fit_type = FIT_TYPE_NUM.jp;
        }

        this.fittingService.downloadDispatchPdf(dates, () => this.setState({pending: false}), this.state.isJpList);
    }

    toggleFilter(e) {
        const value = e.target.value;
        let items = this.state.filterItems;

        if (e.target.checked) {
            items.push(value);
        } else {
            items = items.filter(item => item !== value);
        }

        this.setState({filterItems: items});
    }

    render() {
        let initialDate = `${this.initialStartDate} - ${this.initialEndDate}`;

        return (
            <>
                <h1>Fitters</h1>

                <div className="panel">
                    <div className="panel__top">
                        <div className="panel__title">Appointment List</div>
                        <div className="action">
                            <div className="panel__filter">

                                {Access.isAllowed('Fitter', 'reportDispatch') ? <div className="item">
                                        <span className="link"
                                              onClick={() => this.toggleDispatchModal(true, false)}>Master Runsheet (Prepare Dispatch)
                                    </span>
                                </div> : ''}

                                {/*{Access.isAllowed('Fitter', 'jpList') ?*/}
                                    <div className="item">
                                        <span
                                            className="link"
                                            onClick={() => this.toggleDispatchModal(true, true)}>JP Runsheet
                                        </span>
                                    </div>
                                {/*: ''}*/}


                                {Access.isAllowed('Fitter', 'reportDispatch') ? <div className="item">
                                    <span className="link"
                                          onClick={() => this.doShowDatesModal()}>Download Report</span>
                                </div> : ''}

                                <div className="item filter__search"><TextField
                                    label="Search Name / Location / Phone / Date / Fitter" size="small"
                                    onChange={this.onChangeSearch}/></div>
                                <div className="item">
                                    <Select
                                        label="Show"
                                        size="small"
                                        options={[
                                            {
                                                value: 'all',
                                                label: 'All'
                                            },
                                            {
                                                value: 'today',
                                                label: 'Today'
                                            },
                                            {
                                                value: 'future',
                                                label: 'Future'
                                            },
                                            {
                                                value: 'canceled',
                                                label: 'Cancelled'
                                            },

                                            {
                                                value: 'fitType_1',
                                                label: 'Check measure'
                                            },
                                            {
                                                value: 'fitType_2',
                                                label: 'Service call'
                                            },
                                            {
                                                value: 'fitType_3',
                                                label: 'Fitting'
                                            },
                                            {
                                                value: 'fitType_4',
                                                label: 'JP'
                                            },


                                        ]}
                                        defaultValue={this.filterValue}
                                        onChange={this.onChangeFilter}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="panel__body panel__body--no-padding">
                        <Modal
                            status={this.modalStatusDates}
                            className="xs__full xs__top"
                        >
                            <div className="abc__modal__content">
                                <div className="panel">
                                    <div className="panel__top">
                                        <div className="panel__title">Choose dates</div>
                                    </div>
                                    <div className="panel__body">
                                        <div className="ih">
                                            <div className="label">Select a start and end date</div>
                                            <ReactDatePicker
                                                className="datepicker"
                                                placeholder="From"

                                                selectsRange={true}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                value={initialDate}
                                                // minDate={new Date()}
                                                selected={this.state.startDate}
                                                onChange={this.chooseDatesFromTo}
                                                // inline
                                            />
                                        </div>
                                        <div>
                                            <BoxGroup label="Type">
                                                <CheckBox
                                                    name="measure_type"
                                                    checked={[MEASUREMENTS.IndoorBlinds].includes(this.state.filterItems)}
                                                    value={'1'}
                                                    onChange={this.toggleFilter}
                                                >Indoor Blinds</CheckBox>
                                                <CheckBox
                                                    name="measure_type"
                                                    checked={[MEASUREMENTS.IndoorShutters].includes(this.state.filterItems)}
                                                    value={'2'}
                                                    onChange={this.toggleFilter}
                                                >Indoor Shutters</CheckBox>
                                                <CheckBox
                                                    name="measure_type"
                                                    checked={[MEASUREMENTS.Curtains].includes(this.state.filterItems)}
                                                    value={'3'}
                                                    onChange={this.toggleFilter}
                                                >Curtains</CheckBox>
                                                <CheckBox
                                                    name="measure_type"
                                                    checked={[MEASUREMENTS.OutdoorBlinds].includes(this.state.filterItems)}
                                                    value={'4'}
                                                    onChange={this.toggleFilter}
                                                >Outdoor (Blinds & Shutters)</CheckBox>
                                                <CheckBox
                                                    name="measure_type"
                                                    checked={[MEASUREMENTS.Secure].includes(this.state.filterItems)}
                                                    value={'5'}
                                                    onChange={this.toggleFilter}
                                                >Security doors & window screens</CheckBox>
                                                <CheckBox
                                                    name="measure_type"
                                                    checked={[MEASUREMENTS.Commercial].includes(this.state.filterItems)}
                                                    value={'6'}
                                                    onChange={this.toggleFilter}
                                                >Commercial</CheckBox>
                                                <CheckBox
                                                    name="measure_type"
                                                    checked={[MEASUREMENTS.Hpb].includes(this.state.filterItems)}
                                                    value={'7'}
                                                    onChange={this.toggleFilter}
                                                >Hpb</CheckBox>
                                                <CheckBox
                                                    name="measure_type"
                                                    checked={[MEASUREMENTS.Abco].includes(this.state.filterItems)}
                                                    value={'8'}
                                                    onChange={this.toggleFilter}
                                                >Abco</CheckBox>
                                                <CheckBox
                                                    name="measure_type"
                                                    checked={[MEASUREMENTS.Wholesale].includes(this.state.filterItems)}
                                                    value={'9'}
                                                    onChange={this.toggleFilter}
                                                >Wholesale</CheckBox>
                                            </BoxGroup>
                                        </div>
                                    </div>

                                    <div className="panel__bottom">
                                        <div className="bh">
                                            <button disabled={this.state.pending} className="btn"
                                                    onClick={this.downloadReport}>Download
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        <Modal
                            status={this.dispatchModalStatus}
                            className="xs__full xs__top"
                        >
                            <div className="abc__modal__content">
                                <div className="panel">
                                    <div className="panel__top">
                                        <div className="panel__title">Choose dates</div>
                                    </div>
                                    <div className="panel__body">
                                        <div className="ih">
                                            <div className="label">Select a start and end date</div>
                                            <ReactDatePicker
                                                className="datepicker"
                                                placeholder="From"

                                                selectsRange={true}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                value={initialDate}
                                                // minDate={new Date()}
                                                selected={this.state.startDate}
                                                onChange={this.chooseDatesFromTo}
                                            />
                                        </div>

                                        {/*{Access.isAllowed('Fitter', 'switchPdfType') &&*/}
                                        {/*    <BoxGroup label="" inline={true}>*/}
                                        {/*        <RadioBox*/}
                                        {/*            name={'type_dispatch'}*/}
                                        {/*            onChange={() => this.fittingService.fittingDispatchType = 'new'}*/}
                                        {/*            defaultChecked={true}*/}
                                        {/*        >New Style</RadioBox>*/}
                                        {/*        <RadioBox*/}
                                        {/*            name={'type_dispatch'}*/}
                                        {/*            onChange={() => this.fittingService.fittingDispatchType = 'old'}*/}
                                        {/*        >Old Style</RadioBox>*/}
                                        {/*    </BoxGroup>*/}
                                        {/*}*/}
                                    </div>

                                    <div className="panel__bottom">
                                        <div className="bh">
                                            <button disabled={this.state.pending} className="btn"
                                                    onClick={this.downloadDispatchPDF}>Download
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>


                        <ul className="booking__list">
                            <li className="booking__list__heading">
                                <div className="booking__list__name">Customer Name</div>
                                <div className="booking__list__address">Location</div>
                                <div className="booking__list__address">Phone</div>
                                <div className="booking__list__date">Fitting Date</div>
                                <div className="booking__list__time">Time</div>
                                <div className="booking__list__time">Type</div>
                                <div className="booking__list__reps">Assigned to</div>
                                <div className="booking__list__action"></div>
                            </li>
                            {this.fittingService.list.map((row, i) => {
                                let productSeparator = '';

                                return (
                                    <li key={i} className={row.status === '2' ? 'cancelled' : ''}>
                                        <div className="booking__list__name">
                                            <span
                                                className="booking__list__job__info"
                                                onClick={() => this.doShowRepModal(row.id)}
                                            >{row.booking.firstName + ' ' + row.booking.lastName}</span></div>
                                        <div
                                            className="booking__list__address">{row.booking.address}{row.booking.suburb ? ', ' + row.booking.suburb : ''}</div>
                                        <div className="booking__list__phone">{row.booking.phone}</div>
                                        <div className="booking__list__date"><Moment
                                            format={'DD MMMM YYYY'}>{row.date}</Moment></div>
                                        <div
                                            className="booking__list__time">{moment(row.date + ' ' + row.timeFrom).format('a').toUpperCase()}</div>
                                        <div
                                            className="booking__list__type">{row.productList ? row.productList.map(product => {
                                            let result = productSeparator + product.type_name;
                                            productSeparator = ', ';

                                            return result;
                                        }) : ''}</div>
                                        <div className="booking__list__reps">{row.fitterName}</div>

                                        <div className="booking__list__action">
                                            {Access.isAllowed('MeasureAndQuote', 'dottedLink') ?
                                                <Popup
                                                    ref={this.refPopup}
                                                    container={'header'}
                                                    trigger={
                                                        <div className="menu__dots__trigger">
                                                            <div className="menu__dots__trigger__dots"></div>
                                                            <div className="menu__dots__trigger__dots"></div>
                                                            <div className="menu__dots__trigger__dots"></div>
                                                        </div>
                                                    }
                                                    action={'click'}
                                                    className={'menu__dots menu__dots--booking-list abc__toggle'}
                                                >
                                                    <div className="menu__dots__dropdown">
                                                        <div className="menu__dots__list">
                                                            <div className="menu__dots__list__item"
                                                                 onClick={() => this.doShowRepModal(row.id)}>View
                                                            </div>
                                                            <div className="menu__dots__list__item" onClick={() => {
                                                                this.selectedCancelId = row.id;
                                                                this.modalCancelConfirm.show = true;
                                                                this.setState({});
                                                            }}>Cancel
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Popup>
                                                : ''
                                            }
                                        </div>

                                    </li>
                                )
                            })}

                        </ul>

                    </div>

                    {
                        this.fittingService.pagination.hasMore ?
                            <div className="panel__bottom">
                                <div className="pagination">
                                    <input className="btn" type={'button'} value={'Load more'} onClick={this.loadMore}/>
                                </div>
                            </div>
                            : ''
                    }

                </div>

                <Modal
                    status={this.modalStatusRep}
                    className="modal__booking__information"
                >
                    <div className="abc__modal__content">
                        <Tabs
                            items={
                                [
                                    'Booking Information',
                                    'Logs',
                                ]
                            }
                        >

                            <div className="panel">
                                <div className="panel__top">
                                    <div className="panel__title">Fitting Information</div>
                                </div>
                                <div className="panel__body">
                                    {this.selectedFitting ?
                                        <div className="modal__booking__information__column">

                                            <div className="item">
                                                <div className="form__row">
                                                    <div className="form__label">Customer's Name</div>
                                                    <div
                                                        className="form__field">{this.selectedFitting.booking.firstName} {this.selectedFitting.booking.lastName}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Email</div>
                                                    <div
                                                        className="form__field">{this.selectedFitting.booking.email}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Company</div>
                                                    <div
                                                        className="form__field">{this.selectedFitting.booking.company}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Phone</div>
                                                    <div
                                                        className="form__field">{this.selectedFitting.booking.phone}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Call Customer Before Appointment</div>
                                                    <div
                                                        className="form__field">{this.selectedFitting.booking.callBefore ? 'Yes' : 'No'}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Unit No.</div>
                                                    <div
                                                        className="form__field">{this.selectedFitting.booking.unitNo}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Address</div>
                                                    <div
                                                        className="form__field">{this.selectedFitting.booking.address}, {this.selectedFitting.booking.suburb}, {this.selectedFitting.booking.postalCode}</div>
                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">Date</div>
                                                    <div
                                                        className="form__field">{moment(this.selectedFitting.date).format('DD MMMM YYYY dddd')}</div>
                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">Appointment Start</div>
                                                    <div className="form__field">
                                                        {moment(this.selectedFitting.date + ' ' + this.selectedFitting.timeFrom).format('h:mm a').toUpperCase()} - {moment(this.selectedFitting.date + ' ' + this.selectedFitting.timeTo).format('h:mm a').toUpperCase()}
                                                    </div>
                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">SAP No.</div>
                                                    <div className="form__field">{this.selectedFitting.customerNo}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">All windows to be cleared</div>
                                                    <div
                                                        className="form__field">{this.selectedFitting.windows ? 'YES' : 'NO'}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Balance Due</div>
                                                    <div className="form__field">{this.selectedFitting.balanceDue}</div>
                                                </div>

                                                {/*<div className="form__row">*/}
                                                {/*    <div className="form__label">Contract Price</div>*/}
                                                {/*    <div className="form__field">{this.selectedFitting.contractPrice}</div>*/}
                                                {/*</div>*/}

                                                <div className="form__row">
                                                    <div className="form__label">Product Location</div>
                                                    <div
                                                        className="form__fields">{this.selectedFitting.productLocationText}</div>
                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">Notes</div>
                                                    <div className="form__fields">{this.selectedFitting.notes}</div>

                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">Products</div>
                                                    {this.products.map((product) => {
                                                        return (
                                                            <div
                                                                key={product.id}>{product.quantity} {product.product ? product.product : product.short_name}({product.name})</div>
                                                        )
                                                    })}
                                                </div>

                                            </div>

                                        </div>
                                        : ''}
                                </div>

                            </div>
                            <div>
                                <div className="panel">
                                    <div className="panel__top">
                                        <div className="panel__title">Logs</div>
                                    </div>
                                    <div className="panel__body">
                                        <Logs calendarEvent={{fitting: this.selectedFitting}}/>
                                    </div>

                                </div>
                            </div>
                        </Tabs>

                    </div>


                    <div className="abc__modal__content">
                        {this.state.logs.length > 0 ?
                            <div className="panel">
                                <div className="panel__top">
                                    <div className="panel__title">Logs</div>
                                </div>
                                <div className="panel__body">

                                    {this.state.logs.map(item => {
                                        let shortcat = '';

                                        if (item.full_name) {
                                            const shortcatParts = item.full_name.split(' ');
                                            shortcat += shortcatParts[0].substr(0, 1).toUpperCase();

                                            if (shortcatParts[1]) {
                                                shortcat += shortcatParts[1].substr(0, 1).toUpperCase();
                                            }
                                        }

                                        return (
                                            <div className="logs" key={Math.random()}>
                                                <div className="logs__column">
                                                    <div className="logs__avatar">{shortcat}</div>
                                                </div>
                                                <div className="logs__column">
                                                    <div className="logs__info">
                                                        <span className="logs__info__name">{item.full_name}</span>
                                                        <span
                                                            className="logs__info__time">{moment(item.event_date).format('DD MMM YYYY h:mma')}</span>
                                                    </div>
                                                    <div className="logs__details">{item.caption} | {item.message}</div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                            : ''}

                    </div>

                </Modal>
                <Modal
                    status={this.modalStatusPdf}
                >

                    <div className="abc__modal__content">
                        <div className="panel">
                            <div className="panel__top">
                                <div className="panel__title">Fitting Information</div>
                            </div>
                            <div className="panel__body">
                                {this.selectedFitting ?

                                    <div className="calendar__booking__box">

                                        <div className="item">
                                            <div className="form__row">
                                                <div className="form__label">Quote Date:</div>
                                                <div className="form__field">Datepicker</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Customer's Name</div>
                                                <div className="form__field">Autocomplete List Customers Name</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Email</div>
                                                <div className="form__field">email@email.com</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Phone</div>
                                                <div className="form__field">{this.selectedFitting.phone}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Unit No.</div>
                                                <div className="form__field">{this.selectedFitting.unitNo}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Address</div>
                                                <div
                                                    className="form__field">{this.selectedFitting.address}, {this.selectedFitting.suburb}, {this.selectedFitting.postalCode}</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">M&Q By</div>
                                                <div className="form__field">Consultant Name</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">Customer Number:</div>
                                                <div className="form__field">TEXTBOX</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">Balance Due:</div>
                                                <div className="form__field">TEXTBOX</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label"></div>
                                                <div className="form__field">[ ] All windows to be cleared</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">Notes</div>
                                                <div className="form__field">Textarea</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">Type:</div>
                                                <div className="form__field">Fitting</div>
                                            </div>

                                        </div>

                                    </div>
                                    : ''}
                            </div>

                        </div>

                    </div>

                </Modal>


                <Modal
                    status={this.modalCancelConfirm}
                >

                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Cancel Fitting</div>
                        </div>
                        <div className="panel__body">
                            Are you sure you want to cancel this fitting?

                        </div>
                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={this.onDelete}>Yes</button>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        this.modalCancelConfirm.show = false;
                                        this.selectedCancelId = null;
                                        this.setState({})
                                    }}
                                >No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

            </>
        );
    }
}