import React from "react";
import "./reportsStyles.css";
import Select from "../components/form/Select";
import DatePicker from "react-datepicker";
import moment from "moment";
import {apiRequest} from "../components/Globals";
import {notify} from "../components/Notify";
// import ShowroomReports from "./ShowroomReports";
// import ShowroomReports2 from "./ShowroomReports2";
// import ShowroomReports3 from "./ShowroomReports3";
// import ShowroomReports4 from "./ShowroomReports4";
import ReportExpensesModal from "./ReportExpensesModal";
// import Chart from "react-apexcharts";

export default class Reports extends React.Component {
    numberFormat(num, simpleFix) {
        if (!num) {
            return 0;
        }

        if (simpleFix) {
            return (parseFloat(num)).toFixed(2);
        }

        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    customTooltip(e, series, seriesIndex, dataPointIndex, w) {
        const currentYear = e[0]?.tooltipData[dataPointIndex];
        const lastYear = e[1] ? e[1]?.tooltipData[dataPointIndex] : {};
        const isCompare = e.length > 1;

        console.log('eeee', e)
        // console.log('ind', seriesIndex)
        console.log(dataPointIndex)
        console.log(w)

        let currentYearDate;
        let lastYearDate;

        if (this.reportType === 'weekly') {
            let lastDate;
            if (isCompare) {
                lastDate = e[1].dates[dataPointIndex]?.split('-');

                if (lastDate && lastDate[0]) {
                    lastDate[0] = lastDate[0] + ' ' + e[1].name;
                }
                if (lastDate && lastDate[1]) {
                    lastDate[1] = moment(lastDate[1]).set('year', e[1].name).format('DD MMM YYYY');
                    lastDate = lastDate.join(' - ');
                    lastYearDate = lastDate;
                }
            }

            if (e.length > 2) {
                e.some(seriesData => {
                    if (seriesData.name === this.yearButtons[0].label) {
                        currentYearDate = seriesData.dates[dataPointIndex];
                        return true;
                    }
                    return false;
                })
            } else {
                currentYearDate = `${e[0].dates[dataPointIndex]}`;
            }


        } else if (this.reportType === 'monthly') {
            currentYearDate = `${e[0].dates[dataPointIndex]}`;
            if (isCompare) {
                lastYearDate = `${e[1].dates[dataPointIndex]}`;
            }

        } else if (this.reportType === 'yearly') {
            currentYearDate = moment(e[0].dates[dataPointIndex]).format('MMMM YYYY');

            if (isCompare) {
                lastYearDate = moment(e[1].dates[dataPointIndex]).set('year', e[1].name).format('MMMM YYYY');
            }
        } else {
            currentYearDate = moment(e[0].dates[dataPointIndex]).format('DD MMM YYYY');

            if (isCompare) {
                lastYearDate = moment(e[1].dates[dataPointIndex]).set('year', e[1].name).format('DD MMM YYYY');
            }
        }

        if (e?.length > 1) {
            return `
                 <div style="padding: 10px; display: flex; gap: 15px">
                    <div>
                      <div style="display: flex; gap: 10px; margin-bottom: 10px; align-items: center">
                          <div style="width: 15px; height: 15px; background-color: ${w.globals.colors[0]}; border-radius: 50%"></div>
                          <b>${currentYearDate}</b>
                      </div>
                       <ul>
                            <li>Total Leads: ${this.numberFormat(currentYear.total)}</li>
                            <li>Indoor Blinds: ${this.numberFormat(currentYear.blinds)}</li>
                            <li>Curtains: ${this.numberFormat(currentYear.curtains)}</li>
                            <li>Do not know: ${this.numberFormat(currentYear.do_not_know)}</li>
                            <li>Indoor Shutters: ${this.numberFormat(currentYear.indoor_shutters)}</li>
                            <li>Outdoor Blinds: ${this.numberFormat(currentYear.outdoor_blinds)}</li>
                            <li>Security Door Screen: ${this.numberFormat(currentYear.security_door_screen)}</li>
                            <li>Self Booking: ${this.numberFormat(currentYear.book)} (${currentYear.percent}%)</li>
                            <li>Showroom Leads: ${this.numberFormat(currentYear.showroom)}</li>
                            <li>Cancellations: ${this.numberFormat(currentYear.cancellation)}</li>
                            <li>Expenses: $${this.numberFormat(currentYear.expenses)}</li>
                            <li>Cost per Lead: $${this.numberFormat(currentYear.costLead)}</li>
                            ${!e[0].isShowRoom ? `<li>Request Call-back: ${this.numberFormat(currentYear.callBack)} </li>
                            <li>Web Enquiry: ${this.numberFormat(currentYear.enquiry)} </li>` : ''}
                        </ul>
                    </div>

                    ${lastYear ?
                `<div>
                            <div style="display: flex; gap: 10px; margin-bottom: 10px; align-items: center">
                                <div style="width: 15px; height: 15px; background-color: ${w.globals.colors[1]}; border-radius: 50%"></div>
                                <b>${lastYearDate}</b>
                            </div>
                            <ul style="margin-bottom: 0">
                                <li>Total Leads: ${this.numberFormat(lastYear.total)}</li>
                                <li>Indoor Blinds: ${this.numberFormat(lastYear.blinds)}</li>
                                <li>Curtains: ${this.numberFormat(lastYear.curtains)}</li>
                                <li>Do not know: ${this.numberFormat(lastYear.do_not_know)}</li>
                                <li>Indoor Shutters: ${this.numberFormat(lastYear.indoor_shutters)}</li>
                                <li>Outdoor Blinds: ${this.numberFormat(lastYear.outdoor_blinds)}</li>
                                <li>Security Door Screen: ${this.numberFormat(lastYear.security_door_screen)}</li>
                                <li>Self Booking: ${this.numberFormat(lastYear.book)} (${lastYear.percent}%)</li>
                                <li>Showroom Leads: ${this.numberFormat(lastYear.showroom)}</li>
                                <li>Cancellations: ${this.numberFormat(lastYear.cancellation)}</li>
                                <li>Expenses: $${this.numberFormat(lastYear.expenses)}</li>
                                <li>Cost per Lead: $${this.numberFormat(lastYear.costLead)}</li>
                                ${!e[1].isShowRoom ? `<li>Request Call-back: ${this.numberFormat(lastYear.callBack)} </li>
                                    <li>Web Enquiry: ${this.numberFormat(lastYear.enquiry)} </li>` : ''}
                            </ul>
                        </div>`
                : ""}
                </div>
            `
        }

        return `
            <div style="padding: 10px">
              <div style="display: flex; gap: 10px; margin-bottom: 10px; align-items: center">
                  <div style="width: 15px; height: 15px; background-color: ${w.globals.colors[0]}; border-radius: 50%"></div>
                  <b>${currentYearDate}</b>
              </div>

              <ul style="margin-bottom: 0">
                    <li>Total Leads: ${this.numberFormat(currentYear.total)}</li>
                    <li>Indoor Blinds: ${this.numberFormat(currentYear.blinds)}</li>
                    <li>Curtains: ${this.numberFormat(currentYear.curtains)}</li>
                    <li>Do not know: ${this.numberFormat(currentYear.do_not_know)}</li>
                    <li>Indoor Shutters: ${this.numberFormat(currentYear.indoor_shutters)}</li>
                    <li>Outdoor Blinds: ${this.numberFormat(currentYear.outdoor_blinds)}</li>
                    <li>Security Door Screen: ${this.numberFormat(currentYear.security_door_screen)}</li>
                    <li>Self Booking: ${this.numberFormat(currentYear.book)} (${currentYear.percent}%)</li>
                    <li>Showroom Leads: ${this.numberFormat(currentYear.showroom)}</li>
                    <li>Cancellations: ${this.numberFormat(currentYear.cancellation)}</li>
                    <li>Expenses: $${this.numberFormat(currentYear.expenses)}</li>
                    <li>Cost per Lead: $${this.numberFormat(currentYear.costLead)}</li>
                    ${(!e[0].isShowRoom) ? `<div>Request Call-back: ${this.numberFormat(currentYear.callBack)} </div>
                    <li>Web Enquiry: ${this.numberFormat(currentYear.enquiry)} </li>` : ''}
                </ul>
            </div>
       `
    }

    state = {
        startDate: moment(new Date()).add(-30, "days")._d,
        endDate: new Date(),
        data: [],
        loading: false,

        series: [],
        options: {
            chart: {
                type: 'area',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#77B6EA', '#545454', '#9dba37', '#e94b4b'],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: 'smooth'
            },
            title: {
                text: 'Booking Report',
                align: 'left'
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            markers: {
                size: 4
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5
            },
            tooltip: {
                custom: ({
                             series,
                             seriesIndex,
                             dataPointIndex,
                             w
                         }) => this.customTooltip(this.state.series, series, seriesIndex, dataPointIndex, w)
            }
        },

    };

    payload: {} = {};


    reportList: {} = {
        data: [],
        btnSelfBooked: 0,
        btnShowroomBooked: 0,
        btnCallBack: 0,
        btnEnquiry: 0,
        cancellation: 0,
        expenses: 0,
        costLead: 0
    };
    reportType: string = 'daily';
    yearButtons: [] = [];

    stats: {} = {
        current: {
            callBack: 0,
            selfBooked: 0,
            enquiry: 0,
            showroomBook: 0
        },
        previous: {
            callBack: 0,
            selfBooked: 0,
            enquiry: 0,
            showroomBook: 0
        }
    }

    clickedBtn = null;

    countPercents = 0;
    bookCount = 0;
    bookPercents = 0;
    averageTotalPercent = 0;


    constructor(props) {
        super(props);

        this.expensesModalRef = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.chooseCharYear = this.chooseCharYear.bind(this);
        this.customTooltip = this.customTooltip.bind(this);
        this.getYearBtn = this.getYearBtn.bind(this);
        this.calcTotalPercents = this.calcTotalPercents.bind(this);
        this.numberFormat = this.numberFormat.bind(this);
        this.calcPercents = this.calcPercents.bind(this);
        this.getArrowClassDirection = this.getArrowClassDirection.bind(this);
        this.getUpDownArrowSymbol = this.getUpDownArrowSymbol.bind(this);

    }

    componentDidMount() {
        this.buildReports();
    }


    chooseCharYear(year, firstBtn) {
        this.clickedBtn = {
            year: year,
            firstBtn: firstBtn
        }

        if (year.btnPressed === true && year.reports.length === 0) {
            return this.getReports(!firstBtn, year);
        }

        let removingIndex;

        year.reports.forEach(yearReport => {
            removingIndex = null;

            this.reportList.data.some((report, key) => {
                if (yearReport.id === report.id) {
                    removingIndex = key.toString();
                    return true;
                }
                return false;
            })

            if (removingIndex) {
                this.reportList.data.splice(removingIndex, 1);
            }
        })

        this.yearButtons.some(btn => {
            if (btn.label === year.btnYear) {
                btn.reports = [];
                return true;
            }
            return false;
        })

        this.calcYearBtn();

        this.setState({
            series: this.getSeries(),
            data: this.reportList.data,
            options: {
                ...this.getXAxis(),
            }
        })
        this.setState({data: this.reportList.data});
    }

    getYearBtn(date, selectDate) {
        this.yearButtons = [];

        [0, 1, 2, 3].forEach((btn, i) => {
            let yearFrom = moment(date[0]).add(-btn, 'years');
            let yearTo = moment(date[1]).add(-btn, 'years');

            this.yearButtons.push({
                pressed: i === 0 && selectDate,
                yearFrom: yearFrom.format('YYYY-MM-DD'),
                yearTo: yearTo.format('YYYY-MM-DD'),
                label: yearFrom.format('YYYY'),
                reports: [],
                totalBooked: 0,
                totalShowroom: 0,
                totalCallBack: 0,
                totalEnquiry: 0,
                totalCancellation: 0,
                totalExpenses: 0,
                totalCostLead: 0
            });
        })
    }

    handleChange(date, dd) {
        if (date[0] && date[1]) {
            this.clickedBtn = null;
            this.getYearBtn(date, true);
        }

        this.setState({
            startDate: date[0],
            endDate: date[1],
        });
    }

    buildReports() {
        this.getYearBtn([this.state.startDate, this.state.endDate]);

        this.reportList.data = [];
        this.countPercents = 0;
        this.bookCount = 0;
        this.bookPercents = 0;

        this.reportList.totalBooked = 0;
        this.reportList.totalShowroom = 0;
        this.reportList.totalCallBack = 0;
        this.reportList.totalEnquiry = 0;
        this.reportList.cancellation = 0;
        this.reportList.expenses = 0;
        this.reportList.costLead = 0;

        this.yearButtons.forEach(btn => {
            btn.reports = [];
        });

        // [1, 2].forEach(request => {
        [1].forEach(request => {
            this.getReports(request === 2);
        });
    }

    calcYearBtn() {
        this.reportList.btnSelfBooked = 0;
        this.reportList.btnShowroomBooked = 0;
        this.reportList.btnCallBack = 0;
        this.reportList.btnEnquiry = 0;
        this.reportList.cancellation = 0;
        this.reportList.expenses = 0;
        this.reportList.costLead = 0;

        this.yearButtons.forEach((btn, key) => {
            if (btn.pressed === true && key === 0) {
                this.reportList.btnSelfBooked += btn.totalBooked;
                this.reportList.btnShowroomBooked += btn.totalShowroom;
                this.reportList.btnCallBack += btn.totalCallBack;
                this.reportList.btnEnquiry += btn.totalEnquiry;
                this.reportList.cancellation += btn.cancellation;
                this.reportList.expenses += btn.expenses;
                this.reportList.costLead += parseFloat(btn.costLead);
            }
        })
    }

    calcTotalPercents(report) {
        this.countPercents = 0;
        this.bookCount = 0;

        if (report) {
            this.countPercents += parseInt(report.self_percent) * parseInt(report.self_booked);
            this.bookCount += parseInt(report.self_booked);

            return;
        }

        let countPercents = 0;
        let bookCount = 0;

        this.reportList.data.forEach(report => {
            countPercents += parseFloat(report.customHTML.percent) * parseInt(report.customHTML.book);
            bookCount += parseInt(report.customHTML.book);
        })

        if (countPercents > 0) {
            this.averageTotalPercent = countPercents / bookCount;
        }
    }

    calcPercents(aNum, bNum) {
        let res = 0;
        let decrease = aNum - bNum;
        let increase = bNum - aNum;

        let resDecrease = decrease / aNum * 100;
        let resIncrease = increase / bNum * 100;

        if (resDecrease > resIncrease) {
            res = resDecrease;
        } else {
            res = resIncrease;
        }

        return res;
    }

    /** calcStats - calc reports data by year btns
     *
     * @param stats report data
     */
    calcStats(stats) {
        if (stats) {
            const currTotalLeads = parseInt(stats.current.self_booked) + parseInt(stats.current.showroom_booked);
            const prevTotalLeads = parseInt(stats.previous.self_booked) + parseInt(stats.previous.showroom_booked);

            this.stats = {
                current: {
                    total: 0,
                    totalPercent: 0,
                    enquiryPercent: 0,
                    callBackPercent: 0,
                    bookedPercent: 0,
                    showroomPercent: 0,
                    cancellationPercent: 0,
                    expensesPercent: 0,
                    costLeadPercent: 0,
                    period: stats.current.period,
                    enquiry: stats.current.enquiry,
                    callBack: stats.current.callback,
                    selfBooked: stats.current.self_booked,
                    showroomBook: stats.current.showroom_booked,
                    cancellation: stats.current.cancels,
                    expenses: stats.current.expenses,
                    costLead: currTotalLeads > 1 ? stats.current.expenses / currTotalLeads : stats.current.expenses
                },
                previous: {
                    total: 0,
                    totalPercent: 0,
                    enquiryPercent: 0,
                    callBackPercent: 0,
                    bookedPercent: 0,
                    showroomPercent: 0,
                    cancellationPercent: 0,
                    expensesPercent: 0,
                    costLeadPercent: 0,
                    period: stats.previous.period,
                    enquiry: stats.previous.enquiry,
                    callBack: stats.previous.callback,
                    selfBooked: stats.previous.self_booked,
                    showroomBook: stats.previous.showroom_booked,
                    cancellation: stats.current.cancels,
                    expenses: stats.current.expenses,
                    costLead: prevTotalLeads > 1 ? stats.previous.expenses / currTotalLeads : stats.previous.expenses
                }
            }

            //total bookings + showroom percent
            let currTotal = this.stats.current.selfBooked + this.stats.current.showroomBook;
            let prevTotal = this.stats.previous.selfBooked + this.stats.previous.showroomBook;
            if (this.stats.current.selfBooked > 0 || this.stats.current.showroomBook > 0
                || this.stats.previous.selfBooked > 0 || this.stats.previous.showroomBook > 0) {

                this.stats.current.total = currTotal;
                this.stats.previous.total = prevTotal;
                this.stats.current.totalPercent = this.calcPercents(currTotal, prevTotal);
            }

            //total bookings percent
            if (this.stats.current.selfBooked > 0 || this.stats.previous.selfBooked > 0) {
                this.stats.current.bookedPercent = this.calcPercents(this.stats.current.selfBooked, this.stats.previous.selfBooked);
            }

            //total callback percent
            if (this.stats.current.callBack > 0 || this.stats.previous.callBack > 0) {
                this.stats.current.callBackPercent = this.calcPercents(this.stats.current.callBack, this.stats.previous.callBack);
            }

            //total showroom percent
            if (this.stats.current.showroomBook > 0 || this.stats.previous.showroomBook > 0) {
                this.stats.current.showroomPercent = this.calcPercents(this.stats.current.showroomBook, this.stats.previous.showroomBook);
            }

            //total enquiry percent
            if (this.stats.current.enquiry > 0 || this.stats.previous.enquiry > 0) {
                this.stats.current.enquiryPercent = this.calcPercents(this.stats.current.enquiry, this.stats.previous.enquiry);
            }
            //total cancellation percent
            if (this.stats.current.cancellation > 0 || this.stats.previous.cancellation > 0) {
                this.stats.current.cancellationPercent = this.calcPercents(this.stats.current.cancellation, this.stats.previous.cancellation);
            }
            //total expenses percent
            if (this.stats.current.expenses > 0 || this.stats.previous.expenses > 0) {
                this.stats.current.expensesPercent = this.calcPercents(this.stats.current.expenses, this.stats.previous.expenses);
            }
            //total costLead percent
            if (this.stats.current.costLead > 0 || this.stats.previous.costLead > 0) {
                this.stats.current.costLeadPercent = this.calcPercents(this.stats.current.costLead, this.stats.previous.costLead);
            }
        }
    }

    getReports(lastRequest, year) {
        this.payload = {
            "type": this.reportType,
            "dateFrom": moment(this.state.startDate).format('YYYY-MM-DD'),
            "dateTo": moment(this.state.endDate).format('YYYY-MM-DD')
        };

        if (year === undefined) {
            this.clickedBtn = null;
        }

        if (lastRequest) {
            this.payload.dateFrom = year.start;
            this.payload.dateTo = year.end;
        }

        apiRequest('/report', this.payload)
            .then(result => {
                if (!result || !result.custom) {
                    return;
                }

                let mainYear;


                /** set year btns labels  */
                this.yearButtons.some((btn, key) => {
                    if (key === 0) {
                        mainYear = btn.label;
                        return true;
                    }
                    return false;
                });

                result.custom.dates.forEach(report => {
                    let label = report.label;

                    /** prepare detailed report data for tooltip */
                    if (lastRequest) {

                        switch (this.reportType) {
                            case 'daily':
                                let parts = label.split('-');

                                if ((mainYear - parts[0]) > 1) {
                                    parts[0] = mainYear;
                                } else {
                                    parts[0] = parseInt(parts[0]) + 1;
                                }

                                label = parts.join('-');

                                break;

                            case 'weekly':
                                let wParts = label.split('-');
                                let wp1 = moment(wParts[0]).format('YYYY');
                                let wp2 = moment(wParts[1]).format('YYYY');

                                wParts[0] = moment(wParts[0]).set('year', parseInt(wp1) + 1);
                                wParts[1] = moment(wParts[1]).set('year', parseInt(wp2) + 1);
                                label = wParts[0].format('DD MMM YYYY') + ' - ' + wParts[1].format('DD MMM YYYY');

                                break;

                            case 'monthly':
                                let mParts = label.split(',');
                                mParts[0] = moment(mParts[0]).set('year', parseInt(mParts[0]) + 1);
                                label = mParts[0].format('YYYY') + ', ' + mParts[1].trim();

                                break;

                            default:
                                if ((mainYear - label) > 1) {
                                    label = mainYear;
                                } else {
                                    label = (parseInt(label) + 1).toString();
                                }

                                break;

                        }
                    }

                    let bookings = parseInt(report.self_booked);
                    let totalShowBook = bookings + parseInt(report.showroom_booked);
                    let weeklyDateKey;
                    let weeklyShowDate;

                    if (this.reportType === 'weekly') {
                        let splitLabel = label.split('-');
                        weeklyDateKey = `${moment(splitLabel[0])?.format('MMM DD')}`;
                        weeklyShowDate = `${moment(label.split('-')[0])?.format('DD MMM YYYY')} - ${moment(splitLabel[1])?.format('DD MMM YYYY')}`;
                    }

                    this.reportList.data.push({
                        customHTML: {
                            total: totalShowBook,
                            book: bookings,
                            percent: (report.self_percent).toFixed(2),
                            blinds: report.blinds,
                            curtains: report.curtains,
                            do_not_know: report.do_not_know,
                            indoor_shutters: report.indoor_shutters,
                            outdoor_blinds: report.outdoor_blinds,
                            security_door_screen: report.security_door_screen,
                            callBack: report.callback,
                            enquiry: report.enquiry,
                            showroom: parseInt(report.showroom_booked),
                            cancellation: report.canceled ? parseFloat(report.canceled) : report.cancels,
                            expenses: report.expenses ?? 0,
                            costLead: totalShowBook > 1 ? parseFloat(report.expenses / totalShowBook).toFixed(2) : report.expenses
                        },

                        group: lastRequest ? year.btnYear : mainYear,
                        isShowRoom: false,
                        id: 'tmpId' + Math.random(),
                        key: this.reportType === 'weekly' ? weeklyDateKey : label,
                        weekNum: report.week_num ? parseInt(report.week_num) : null,
                        date: weeklyShowDate,
                        value: (parseFloat(report.self_booked) + parseFloat(report.showroom_booked)).toFixed(2),
                    });
                    console.log(this.reportList.data)

                    /** add reports data into year btns and also for report header */
                    if (lastRequest) {
                        this.yearButtons.some(btn => {
                            if (btn.label === year.btnYear) {
                                const totalLeads = parseInt(result.custom.overall.self_booked) + parseInt(result.custom.overall.showroom_booked);
                                btn.totalBooked = result.custom.overall.self_booked;
                                btn.totalShowroom = result.custom.overall.showroom_booked;
                                btn.totalCallBack = result.custom.overall.callback;
                                btn.totalEnquiry = result.custom.overall.enquiry;
                                btn.cancellation = result.custom.overall.cancels;
                                btn.expenses = result.custom.overall.expenses;
                                btn.costLead = !isNaN(totalLeads) && totalLeads > 1 ? parseFloat(result.custom.overall.expenses / totalLeads).toFixed(2) : result.custom.overall.expenses;
                                btn.reports.push(this.reportList.data[this.reportList.data.length - 1]);
                                return true;
                            }
                            return false;
                        })
                        // this.calcYearBtn();

                    } else {
                        this.calcStats(result.custom.stats);

                        this.yearButtons.some((btn, key) => {
                            if (key === 0) {
                                const totalLeads = parseInt(result.custom.overall.self_booked) + parseInt(result.custom.overall.showroom_booked);
                                btn.pressed = true;
                                btn.totalBooked = result.custom.overall.self_booked;
                                btn.totalShowroom = result.custom.overall.showroom_booked;
                                btn.totalCallBack = result.custom.overall.callback;
                                btn.totalEnquiry = result.custom.overall.enquiry;
                                btn.cancellation = result.custom.overall.cancels;
                                btn.expenses = result.custom.overall.expenses;
                                btn.costLead = !isNaN(totalLeads) && totalLeads > 1 ? parseFloat(result.custom.overall.expenses / totalLeads).toFixed(2) : result.custom.overall.expenses;
                                btn.reports.push(this.reportList.data[this.reportList.data.length - 1]);
                                return true;
                            }
                            return false;
                        })
                        this.calcYearBtn();
                    }
                });


                /**prepare report data column depends on selected type of report (daily, weekly, yearly)*/
                switch (this.reportType) {
                    case 'weekly':
                        this.reportList.data.sort(function (a, b) {
                            let aDate = a.weekNum;
                            let bDate = b.weekNum;

                            if (aDate < bDate) {
                                return -1;
                            }
                            if (aDate > bDate) {
                                return 1;
                            }

                            return 0;

                        });

                        this.reportList.data.forEach(rep => {
                            this.reportList.data.some(newRep => {
                                if (newRep.weekNum === rep.weekNum) {
                                    rep.key = newRep.key;
                                    return true;
                                }
                                return false;
                            })
                        })

                        break;

                    case 'yearly':
                        this.reportList.data.sort(function (a, b) {
                            let newDate = a.key;
                            let secDate = b.key;

                            return moment.utc(newDate).diff(moment.utc(secDate));
                        });

                        break;


                    default:
                        this.reportList.data.sort(function compareFn(a, b) {
                            let aDate = a.key.split('-');
                            aDate = aDate[0] * 365 + aDate[1] * 12 + aDate[2];

                            let bDate = b.key.split('-');
                            bDate = bDate[0] * 365 + bDate[1] * 12 + bDate[2];

                            if (aDate < bDate) {
                                return -1;
                            }
                            if (aDate > bDate) {
                                return 1;
                            }

                            return 0;
                        });
                }

                this.calcTotalPercents();


                // set year btns and reports columns data
                this.setState({
                    series: this.getSeries(),
                    data: this.reportList.data,
                    options: {
                        ...this.getXAxis(lastRequest),
                        color: {
                            scale: {
                                [this.yearButtons[0]?.label]: "#000",
                                [this.yearButtons[1]?.label]: "#c1bfbf",
                                [this.yearButtons[2]?.label]: "#c1bfbf",
                                [this.yearButtons[3]?.label]: "#c1bfbf",
                            }
                        },
                    }
                })

            }).catch(error => {
            notify({text: error.message ? error.message : 'No data'});
        });
    }

    getSeries() {
        const series = [];

        this.yearButtons.forEach(btn => {
            if (btn.pressed) {
                series.push({
                    name: btn.label,
                    type: 'area',
                    tooltipData: [],
                    data: [],
                    dates: [],
                    isShowRoom: false
                })
            }
        })

        this.reportList.data.forEach(item => {
            series.some(series => {
                if (series.name === item.group) {
                    if (this.reportType === 'daily' || this.reportType === 'monthly' || this.reportType === 'yearly') {
                        series.dates.push(item.key);
                    }
                    if (this.reportType === 'weekly') {
                        series.dates.push(item.date);
                    }
                    series.isShowRoom = item.isShowRoom;
                    series.tooltipData.push(item.customHTML);
                    series.data.push(item.value);
                    return true;
                }
                return false;
            });
        })
        return series;
    }

    getXAxis(lastRequest) {
        let options = {
            ...this.state.options
        }

        options.xaxis = {
            categories: [],
            title: {
                text: this.getReportTypeName()
            }
        }
        options.yaxis = {
            title: {
                text: 'Bookings'
            },
            min: 0,
            max: 0
        };

        this.reportList.data.forEach(item => {
            if (parseInt(item.value) > options.yaxis.max) {
                options.yaxis.max = parseInt(item.value);
            }
        })

        if (lastRequest) {
            return options;
        }

        options.xaxis.categories = this.reportList.data.map(item => {
            if (parseInt(item.value) > options.yaxis.max) {
                options.yaxis.max = parseInt(item.value);
            }

            if (this.reportType === 'daily' || this.reportType === 'monthly' || this.reportType === 'yearly') {
                return item.key;
            }
            if (this.reportType === 'weekly') {
                return item.date;
            }
        })

        return options;
    }

    getReportTypeName() {
        let res = 'Day';

        if (this.reportType === 'weekly') {
            res = 'Week';
        } else if (this.reportType === 'monthly') {
            res = 'Month';
        } else if (this.reportType === 'yearly') {
            res = 'Year';
        }

        return res;
    }

    getArrowClassDirection(headerColumn: string) {
        let res = 'up';

        if (!headerColumn || !this.stats || (headerColumn && !this.stats.previous[headerColumn])) {
            return res;
        }

        if (this.stats.previous[headerColumn] > this.stats.current[headerColumn]) {
            res = 'down';
        }

        return res;
    }

    getUpDownArrowSymbol(headerColumn: string) {
        let res = 'down';

        if (headerColumn === 'total' && (this.stats.previous.total < this.stats.current.total
            || (this.reportList.btnShowroomBooked + this.reportList.btnSelfBooked) === 0)) {
            res = 'up';
        }
        if (headerColumn === 'selfBooked' && (this.stats.previous.selfBooked < this.stats.current.selfBooked
            || this.reportList.btnSelfBooked === 0)) {
            res = 'up';
        }
        if (headerColumn === 'showroomBook' && (this.stats.previous.showroomBook < this.stats.current.showroomBook
            || this.reportList.btnShowroomBooked === 0)) {
            res = 'up';
        }
        if (headerColumn === 'callBack' && (this.stats.previous.callBack < this.stats.current.callBack
            || this.reportList.btnCallBack === 0)) {
            res = 'up';
        }
        if (headerColumn === 'enquiry' && (this.stats.previous.enquiry < this.stats.current.enquiry
            || this.reportList.btnEnquiry === 0)) {
            res = 'up';
        }

        return res === 'up' ? String.fromCharCode(9650) : String.fromCharCode(9660);
    }

    render() {
        // console.log(this.state.data)
        // console.log('bbbb', this.yearButtons)
        // console.log('RPRPRPR', this.reportList)
        // console.log(this.state.options)
        // console.log(this.stats)
        if (this.state.series.length === 0) {
            return <>Loading...</>;
        }

        return (
            <>
                <h1>Reports</h1>

                <div className="panel">
                    <div className="panel__top">
                        <div className="panel__title">
                            Leads
                        </div>
                        <div className="panel__actions">
                            <div className="panel__filter">
                                <button className={'btn'}
                                        disabled={this.state.loading}
                                        onClick={_ => this.expensesModalRef.current.toggleExpensesModal(true)}
                                >
                                    Expenses
                                </button>
                                <Select
                                    size="small"
                                    label=""
                                    options={[{
                                        label: 'Daily',
                                        value: 'daily'
                                    }, {
                                        label: 'Weekly (Starts Monday)',
                                        value: 'weekly'
                                    }, {
                                        label: 'Monthly',
                                        value: 'monthly'
                                    }, {
                                        label: 'Yearly',
                                        value: 'yearly'
                                    },]}
                                    onChange={(e) => {
                                        this.reportType = e.target.value;
                                        this.buildReports();
                                    }}
                                    defaultValue={this.reportType}
                                />

                                <DatePicker
                                    className="datepicker"
                                    placeholder="From"
                                    dateFormat="dd MMMM yyyy"
                                    selectsRange={true}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    maxDate={new Date()}
                                    ariaLabelledBy={''}
                                    onCalendarClose={() => {
                                        if (this.state.startDate && this.state.endDate) {
                                            this.buildReports();
                                        }
                                    }}
                                    ariaDescribedBy={''}
                                    selected={this.state.startDate}
                                    onChange={this.handleChange}
                                />

                                {/*<button className="btn btn--s" onClick={() => this.buildReports()}>Update</button>*/}

                            </div>


                        </div>
                    </div>
                    <div className="panel__body">
                        <div className="report__summary">
                            <div className="item">
                                <div className="report__summary__label">Leads</div>
                                <div
                                    className="report__summary__value">{this.numberFormat(this.reportList.btnShowroomBooked + this.reportList.btnSelfBooked)}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.getArrowClassDirection('total')}`}> {this.numberFormat(this.stats.current.totalPercent, true)}% {this.getUpDownArrowSymbol('total')}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="report__summary__label">Self Booking</div>
                                <div
                                    className="report__summary__value">{this.reportList.btnSelfBooked > 0 ? `${this.numberFormat(this.reportList.btnSelfBooked)} (${((this.reportList.btnSelfBooked) * 100 / (this.reportList.btnSelfBooked + this.reportList.btnShowroomBooked)).toFixed(2)}%)` : 0}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.getArrowClassDirection('selfBooked')}`}> {this.numberFormat(this.stats.current.bookedPercent, true)}% {this.getUpDownArrowSymbol('selfBooked')}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="report__summary__label">Showroom Leads</div>
                                <div
                                    className="report__summary__value"> {this.numberFormat(this.reportList.btnShowroomBooked)}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.getArrowClassDirection('showroomBook')}`}> {this.numberFormat(this.stats.current.showroomPercent, true)}% {this.getUpDownArrowSymbol('showroomBook')} </span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="report__summary__label">Requested a Callback</div>
                                <div className="report__summary__value">{this.numberFormat(this.reportList.btnCallBack)}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.getArrowClassDirection('callBack')}`}> {this.numberFormat(this.stats.current.callBackPercent, true)}% {this.getUpDownArrowSymbol('callBack')}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="report__summary__label">Web Enquiry</div>
                                <div className="report__summary__value">{this.numberFormat(this.reportList.btnEnquiry)}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.getArrowClassDirection('enquiry')}`}> {this.numberFormat(this.stats.current.enquiryPercent, true)}% {this.getUpDownArrowSymbol('enquiry')}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="report__summary__label">Comparing Date Ranges</div>
                                <div
                                    className="report__summary__value">{`${moment(this.stats.current.period?.start).format('DD MMM YYYY')} - 
                                 ${moment(this.stats.current.period?.end).format('DD MMM YYYY')}`}</div>

                                <div
                                    className="report__summary__value">{`${moment(this.stats.previous.period?.start).format('DD MMM YYYY')} - 
                                 ${moment(this.stats.previous.period?.end).format('DD MMM YYYY')}`}</div>
                            </div>


                            <div className="item">
                                <div className="report__summary__label">Cancellation</div>
                                <div
                                    className="report__summary__value"> {this.numberFormat(this.reportList.cancellation)}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.getArrowClassDirection('cancellation')}`}> {this.numberFormat(this.stats.current.showroomPercent, true)}% {this.stats.previous.showroomBook < this.stats.current.showroomBook || this.reportList.btnShowroomBooked === 0 ? String.fromCharCode(9650) : String.fromCharCode(9660)} </span>
                                </div>
                            </div>

                            <div className="item">
                                <div className="report__summary__label">Expenses $</div>
                                <div
                                    className="report__summary__value"> {this.numberFormat(this.reportList.expenses)}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.getArrowClassDirection('expenses')}`}> {this.numberFormat(this.stats.current.showroomPercent, true)}% {this.stats.previous.showroomBook < this.stats.current.showroomBook || this.reportList.btnShowroomBooked === 0 ? String.fromCharCode(9650) : String.fromCharCode(9660)} </span>
                                </div>
                            </div>

                            <div className="item">
                                <div className="report__summary__label">Cost / Lead</div>
                                <div
                                    className="report__summary__value"> {this.numberFormat(this.reportList.costLead)}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.getArrowClassDirection('costLead')}`}> {this.numberFormat(this.stats.current.showroomPercent, true)}% {this.stats.previous.showroomBook < this.stats.current.showroomBook || this.reportList.btnShowroomBooked === 0 ? String.fromCharCode(9650) : String.fromCharCode(9660)} </span>
                                </div>
                            </div>
                        </div>

                        <div className={'ih'}>
                            <div>
                                {/*<ReactApexCharts*/}
                                {/*    // options={chartOptions}*/}
                                {/*    series={[]}*/}
                                {/*    type="bar"*/}
                                {/*    height={350}*/}
                                {/*/>*/}
                            </div>
                        </div>


                        {this.yearButtons.map((btn, key) => {
                            return (
                                <button style={{color: btn.pressed ? 'green' : ''}} key={key} className={'btn'}
                                        onClick={() => {
                                            btn.pressed = !btn.pressed;

                                            this.chooseCharYear({
                                                start: btn.yearFrom,
                                                end: btn.yearTo,
                                                btnYear: btn.label,
                                                btnPressed: btn.pressed,
                                                reports: btn.reports
                                            }, key === 0)
                                        }}>{btn.label}</button>
                            )
                        })}

                        <table className="tbl__data__list">
                            <tbody>
                            <tr>
                                <th>Date / Week Number</th>
                                <th>Leads</th>
                                <th>Year</th>
                            </tr>

                            {this.state.data.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{moment(item.key).format(`DD MMMM ${item.group}`)}
                                            {/*{item.weekNum ? ` | week number ${item.weekNum}` : ''}*/}
                                        </td>
                                        <td>{item.value.replace('.00', '')}</td>
                                        <td>{item.group}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>

                    </div>

                    {/*{[ShowroomReports, ShowroomReports2, ShowroomReports3, ShowroomReports4].map((Component, key) => {*/}
                    {/*{[ShowroomReports].map((Component, key) => {*/}

                    {/*    return (*/}
                    {/*        <div className={'ih'} key={key}>*/}
                    {/*            <Component*/}
                    {/*                reportState={this.state}*/}
                    {/*                reportList={this.reportList}*/}
                    {/*                reportType={this.reportType}*/}
                    {/*                payload={this.payload}*/}
                    {/*                stats={this.stats}*/}
                    {/*                yearButtons={this.yearButtons}*/}
                    {/*                clickedBtn={this.clickedBtn}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*})}*/}

                </div>

                <ReportExpensesModal ref={this.expensesModalRef}/>
            </>
        );
    }
}