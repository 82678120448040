import {BaseModel} from "./BaseModel";
import {NotEmptyValidator} from "../validators/NotEmptyValidator";
import {RegExpValidator} from "../validators/RegExpValidator";
import {CallbackValidator} from "../validators/CallbackValidator";
import {EmailValidator} from "../validators/EmailValidator";
import {Booking} from "./Booking";

export class Fitting extends BaseModel {
    booking: Booking = new Booking();

    id: number = null;
    userId: number = null;
    fitterName: string = null;
    dateAdded: string = null;
    date: string = null;
    callBefore: boolean = true;
    fixedTime: boolean = false;
    smsReminder: boolean = true;

    customerNo: string = null;
    // type: string = null;
    isAutoTime: boolean = false;
    timeType: string = null;
    timeFrom: string = null;
    timeTo: string = null;
    windows: boolean = false;
    notes: string = null;
    balanceDue: string = null;
    productList: {
        name: string,
        quantity: number,
        type_id: number,
        type_name: string
    }[] = null;
    creatorName: string = null;
    userList: [] = [];
    status: number = null;
    contractPrice: number = null;
    productLocation: number = null;
    productLocationText: string = null;
    takedowns: string = null;
    showroom: string = null;

    constructor() {
        super();

        this.addValidator('customerNo', new NotEmptyValidator('Customer No (SAP)'))
            // .addValidator('type', new NotEmptyValidator())
            // .addValidator('notes', new NotEmptyValidator())
            // .addValidator('balanceDue', new NotEmptyValidator())
            .addValidator('timeFrom', new NotEmptyValidator())
            .addValidator('timeTo', new NotEmptyValidator())
        // .addValidator('contractPrice', new NotEmptyValidator())
        ;
    }

    setProperty(name: string, value: *): this {
        switch (name) {
            case 'time_from':
            case 'time_to':
                break;

            case 'fit_time':
                this.timeFrom = value;

                break;

            case 'fit_time_end':
                this.timeTo = value;

                break;

            case 'fit_date':
                this.date = value;

                break;

            case 'type_id':
                this.type = value;

                break;

            case 'all_windows_clear':
                this.windows = parseInt(value) === 1;
                break;

            case 'showroom':
                if (value) {
                    this.showroom = parseInt(value);
                }
                break;

            case 'due_balance':
                this.balanceDue = value;

                break;

            case 'call_before':
            case 'fitting_call_before':
                this.callBefore = value === '1';
                break;

            case 'fixed_time':
                this.fixedTime = value === '1';
                break;

            case 'user_list':
                this.userList = [];

                if (value) {
                    value.split(',').forEach(item => {
                        if (!this.userList.includes(Number(item))) {
                            this.userList.push(Number(item))
                        }
                    });
                }

                break;

            case 'date':
                // skip
                break;

            case 'send_sms':
                this.smsReminder = parseInt(value) === 1;

                break;

            default:
                super.setProperty(name, value);
        }

        return this;
    }

    getTypeCaption() {
        let typeCaption;

        switch (this.type) {

            case '2':
                typeCaption = 'SC';
                break;

            case '3':
                typeCaption = 'F';
                break;

            case '4':
                typeCaption = 'JP';
                break;

            // case '1':
            default:
                typeCaption = 'CM';
                break;
        }

        return typeCaption;
    }

    productToString() {
        let result = '';

        if (!this.productList || this.productList.length === 0) {
            return result;
        }

        let separator = '';

        this.productList.forEach(product => {
            // Indoor product_id "6" and Outdoor Product_id "11" === Other
            if (product.product_id !== "6" && product.product_id !== "11") {
                product.name = product.short_name;
            }

            result += separator + product.quantity + ' ' + product.name;
            separator = ', ';
        });

        return result;
    }
}
