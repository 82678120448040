import {MEASUREMENTS, ROLES, SHOWROOMS} from "../config/Globals";
import {Auth} from "../components/Auth";

export default class CalendarService {
    static storageKey = 'calendar';
    static version = '1.2';

    showRooms: [] = [];
    blinds: [] = [];
    hideUnavailable: boolean = true;
    calendarType: string = 'consultant';
    isWide: boolean = false;

    init() {
        this.checkInitShowroom();
        this.checkInitBlinds();

        this.calendarType = 'consultant';
        this.isWide = false;
    }

    checkInitShowroom(savedShowrooms: []) {
        const auth = new Auth();
        auth.load();

        if (!savedShowrooms) {
            Object.entries(SHOWROOMS).forEach(([key, showRoomName]) => {
                let isAllow = true;
                if (!auth.showrooms.includes(key) && auth.role !== ROLES.admin) {
                    isAllow = false;
                }
                this.showRooms[key] = isAllow;
            });
        } else {
            savedShowrooms.forEach((isChecked, key) => {
                if (key === 0) {
                    return;
                }
                let isAllow = isChecked;

                if (!auth.showrooms.includes(key.toString()) && auth.role !== ROLES.admin) {
                    isAllow = false;
                }

                this.showRooms[key] = isAllow;
            })
        }
    }

    checkInitBlinds(savedBlinds: []) {
        const auth = new Auth();
        auth.load();

        if (!savedBlinds) {
            Object.entries(MEASUREMENTS).forEach(([measureName, key]) => {
                let isAllow = true;
                if (!auth.blinds.includes(key) && auth.role !== ROLES.admin) {
                    isAllow = false;
                }
                this.blinds[key] = isAllow;
            });
        } else {
            savedBlinds.forEach((isChecked, key) => {
                if (key === 0) {
                    return;
                }
                let isAllow = isChecked;

                if (!auth.blinds.includes(key.toString()) && auth.role !== ROLES.admin) {
                    isAllow = false;
                }

                this.blinds[key] = isAllow;
            })
        }
    }

    load() {
        const auth = new Auth();
        auth.load();
        const data = localStorage.getItem(CalendarService.storageKey);
        let needInit = false;

        // reset if version change
        if (!data) {
            needInit = true;
        }

        let json = JSON.parse(data);

        // reset if version change
        if (needInit || json.version !== CalendarService.version) {
            this.init();
            return;
        }

        this.checkInitShowroom(json.showroom);
        this.checkInitBlinds(json.blinds);
        this.hideUnavailable = json.hideUnavailable;
        this.isWide = json.isWide;
        this.calendarType = json.calendarType ? json.calendarType : 'consultant';
    }

    clear() {
        if (localStorage.getItem(CalendarService.storageKey)) {
            localStorage.removeItem(CalendarService.storageKey);
        }
    }

    save() {
        localStorage.setItem(
            CalendarService.storageKey,
            JSON.stringify({
                version: CalendarService.version,
                showroom: this.showRooms,
                blinds: this.blinds,
                hideUnavailable: this.hideUnavailable,
                calendarType: this.calendarType,
                isWide: this.isWide,
            })
        );
    }

}