export const STATUSES = {
    "UNASSIGNED": 1,
    "ASSIGNED": 2,
    "DELETED": 3,
    "ARCHIVED": 4,
    "CANCELED": 5
};

export const ROLES = {
    admin: 1,
    reps: 2,
    manager: 3,
    showroom: 4,
    commercial: 5,
    fitter: 6,
    wholesale: 7,
    service: 8
};

export const SHOWROOMS = {
    1: "Cannington",
    2: "Wangara",
    3: "Rockingham",
    4: "Osborne Park",
    5: "Commercial",
    6: "Bunbury",
    7: "Hpb",
    8: "Abco",
    9: "Wholesale"
};

export const SHOWROOMS_KEY = {
    cannington: 1,
    wangara: 2,
    rockingham: 3,
    osbornePark: 4,
    commercial: 5,
    bunbury: 6
};

export const SHOWROOMS_AVAILABLE = {
    cannington: 1,
    wangara: 2,
    rockingham: 3,
    osbornePark: 4,
    bunbury: 6,
};

export const SHOWROOMS_OPTIONS = [{
    label: "Cannington",
    value: SHOWROOMS_AVAILABLE.cannington
}, {
    label: "Wangara",
    value: SHOWROOMS_AVAILABLE.wangara
}, {
    label: "Rockingham",
    value: SHOWROOMS_AVAILABLE.rockingham
}, {
    label: "Osborne Park",
    value: SHOWROOMS_AVAILABLE.osbornePark
}]

export const USER_ROLES = [
    {
        value: '5',
        label: 'Commercial'
    },
    {
        value: '2',
        label: 'Consultant',
    },
    {
        value: '6',
        label: 'Fitter'
    },
    {
        value: '3',
        label: 'Manager',
    },
    {
        value: '4',
        label: 'Showroom',
    },
    {
        value: '1',
        label: 'Super User',
    },
    {
        value: '7',
        label: 'Wholesale',
    },
    // {
    //     value: '8',
    //     label: 'Service',
    // },
];

export const RATES = [
    {
        value: '',
        label: '0 Star'
    },
    {
        value: '1',
        label: '1 Star'
    },
    {
        value: '2',
        label: '2 Star'
    },
    {
        value: '3',
        label: '3 Star'
    }
];

export const BOOKING_COUNT = [
    {
        value: '1',
        label: '1'
    },
    {
        value: '2',
        label: '2'
    },
    {
        value: '3',
        label: '3'
    },
    {
        value: '4',
        label: '4'
    },
    {
        value: '5',
        label: '5'
    },
    {
        value: '6',
        label: '6'
    },
    {
        value: '7',
        label: '7'
    },
    {
        value: '8',
        label: '8'
    },
    {
        value: '9',
        label: '9'
    },
    {
        value: '10',
        label: '10'
    },
    {
        value: '0',
        label: 'AUTO'
    },
];

export const MEASUREMENTS = {
    IndoorBlinds: '1',
    IndoorShutters: '2',
    Curtains: '3',
    OutdoorBlinds: '4',
    Secure: '5',
    Commercial: '6',
    Hpb: "7",
    Abco: "8",
    Wholesale: "9"
};

export const MEASUREMENTS_SELECT = [
    {
        value: 1,
        label: 'Indoor Blinds'
    },
    {
        value: 2,
        label: 'Shutters'
    },
    {
        value: 3,
        label: 'Curtains'
    },
    {
        value: 4,
        label: 'Outdoor'
    },
    {
        value: 5,
        label: 'Security doors & window screens'
    },
    {
        value: 6,
        label: 'Commercial'
    }, {
        value: 7,
        label: 'Hpb'
    }, {
        value: 8,
        label: 'Abco'
    }, {
        value: 9,
        label: 'Wholesale'
    },

];

export const DEFAULT_PRODUCTS = [{
    label: "Indoor Blinds",
    value: 'blinds'
}, {
    label: "Outdoor Blinds",
    value: 'outdoorBlinds'
}, {
    label: "Indoor Shutters",
    value: 'indoorShutters'
}, {
    label: "Curtains",
    value: 'curtains'
}, {
    label: "Security doors & window screens",
    value: 'secure'
}];

export const CUSTOMER_SOURCE = [
    {
        value: 9,
        label: 'Existing customer'
    },
    {
        value: 5,
        label: 'Google Ads'
    },
    {
        value: 6,
        label: 'Google Search'
    },
    {
        value: 1,
        label: 'Facebook'
    },
    {
        value: 7,
        label: 'Instagram'
    },
    {
        value: 4,
        label: 'Magazine'
    },
    {
        value: 3,
        label: 'Newspaper'
    },
    {
        value: 10,
        label: 'Online enquiry'
    },
    {
        value: 12,
        label: 'Radio'
    },
    {
        value: 8,
        label: 'Referred by a friend'
    },
    {
        value: 2,
        label: 'TV'
    },
    {
        value: 13,
        label: 'Others'
    },
    {
        value: 14,
        label: 'Self Booking'
    },
    {
        value: 15,
        label: 'Commercial Department'
    },
];

export const FITTING_TYPE = [
    {
        value: '1',
        label: 'Check measure'
    },
    {
        value: '2',
        label: 'Service call'
    },
    {
        value: '3',
        label: 'Fitting'
    },
    {
        value: '4',
        label: 'JP'
    },
];

export const FIT_TYPE_NUM = {
    cm: 1,
    sc: 2,
    fitting: 3,
    jp: 4
}

export const PRODUCT_LOCATION = [
    {
        value: '1',
        label: 'On-Site'
    },
    {
        value: '2',
        label: 'Factory'
    },
];

/**
 * Reflects /v2.blindsystem.com.au/trunk/models/api/FreeQuote.php
 */
export const BOOKING_STATUS = {
    active: '1',
    canceled: '2',
};

export const AVAILABILITY_EVENT_TYPE = {
    default: 1,
    meeting: 2
}


export const FITTING_PRINT_GROUPS_OLD = {
    "Ares": "Group 1 (Holland Group)",
    "Cassette": "Group 1 (Holland Group)",
    "Holland": "Group 1 (Holland Group)",
    "Roller with Motor": "Group 1 (Holland Group)",
    "Headbox": "Group 1 (Holland Group)",
    "Motor only": "Group 1 (Holland Group)",
    "Double Rollers": "Group 1 (Holland Group)",
    "Everyday Pelmet": "Group 1 (Holland Group)",
    "Panel": "Group 1 (Holland Group)",
    "Fascia - Sunboss": "Group 1 (Holland Group)",
    "Fascia - Vision": "Group 1 (Holland Group)",
    "Fascia - Decora": "Group 1 (Holland Group)",
    "Rollers": "Group 1 (Holland Group)",
    "Romans": "Group 1 (Holland Group)",
    "Tricoshade": "Group 1 (Holland Group)",
    "Tripleshade": "Group 1 (Holland Group)",
    "Vertisheers": "Group 1 (Holland Group)",
    "Vision": "Group 1 (Holland Group)",
    "Timber Cedar": "Group 2 (Timber Group)",
    "Timber Nova": "Group 2 (Timber Group)",
    "Timber Verona": "Group 2 (Timber Group)",
    "Woodlux": "Group 2 (Timber Group)",
    "Aluminium Venetians": "Group 3 (Venetians Group)",
    "Aluminium 25mm": "Group 3 (Venetians Group)",
    "Aluminium 50mm": "Group 3 (Venetians Group)",
    "Verticals": "Group 4 (Vertical Group)",
    "Vertical Track Only": "Group 4 (Vertical Group)",
    "Vertical Slats Only": "Group 4 (Vertical Group)"
}


export const FITTING_PRINT_GROUPS = {
    "Ares": "Group 1 (Holland Group)",
    "Cassette": "Group 1 (Holland Group)",
    "Holland": "Group 1 (Holland Group)",
    "Roller with Motor": "Group 1 (Holland Group)",
    "Headbox": "Group 1 (Holland Group)",
    "Motor only": "Group 1 (Holland Group)",
    "Double Rollers": "Group 1 (Holland Group)",
    "Rollers": "Group 1 (Holland Group)",
    "Tricoshade": "Group 1 (Holland Group)",
    "Tripleshade": "Group 1 (Holland Group)",
    "Timber Cedar": "Group 2 (Timber Group)",
    "Timber Nova": "Group 2 (Timber Group)",
    "Timber Verona": "Group 2 (Timber Group)",
    "Aluminium Venetians": "Group 3 (Venetians Group)",
    "Aluminium 25mm": "Group 3 (Venetians Group)",
    "Aluminium 50mm": "Group 3 (Venetians Group)",
    "Verticals": "Group 4 (Vertical Group)",
    "Vertical Track Only": "Group 4 (Vertical Group)",
    "Vertical Slats Only": "Group 4 (Vertical Group)",

    "Romans": "Group 5 (Vision, Romans, Panels, Fascia and Pelmets)",
    "Panel": "Group 5 (Vision, Romans, Panels, Fascia and Pelmets)",
    "Panels": "Group 5 (Vision, Romans, Panels, Fascia and Pelmets)",
    "Vision": "Group 5 (Vision, Romans, Panels, Fascia and Pelmets)",
    "Everyday Pelmet": "Group 5 (Vision, Romans, Panels, Fascia and Pelmets)",
    "Fascia - Decora": "Group 5 (Vision, Romans, Panels, Fascia and Pelmets)",
    "Fascia - Sunboss": "Group 5 (Vision, Romans, Panels, Fascia and Pelmets)",
    "Fascia - Vision": "Group 5 (Vision, Romans, Panels, Fascia and Pelmets)",
    "Vertisheers": "Group 6 (Vertisheers)",

    "Shutters": "Group 7 (Shutters)",
    "Shutters Aluminium": "Group 7 (Shutters)",
    "Shutters Basswood": "Group 7 (Shutters)",
    "Woodlux": "Group 7 (Shutters)"
}

export function getRoleCaption(roleId) {
    let result = '';

    USER_ROLES.some(row => {
        if (row.value === roleId + '') {
            result = row.label;

            return true;
        }

        return false;
    });

    return result;
}

export function getFittingType(id: number) {
    let result = '';

    FITTING_TYPE.some(row => {
        if (row.value === id + '') {
            result = row.label;

            return true;
        }

        return false;
    });

    return result;
}
