import React from 'react';
import CheckBox from "../components/form/CheckBox";
import BoxGroup from "../components/form/BoxGroup";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {apiRequest} from "../components/Globals";
import moment from "moment";
import {AVAILABILITY_EVENT_TYPE, BOOKING_STATUS, MEASUREMENTS_SELECT, ROLES, SHOWROOMS} from "../config/Globals";
import PropTypes from "prop-types";
import Modal from "../components/Modal";
import {Booking} from "../components/form/model/Booking";
import BookingService from "../service/BookingService";
import {User} from "../components/form/model/User";
import Pagination from "../components/Pagination";
import {notify} from "../components/Notify";
import ContextMenu from "../components/ContextMenu";
import ContextTarget from "../components/ContextTarget";
import EventItem from "../components/EventItem";
import {Auth} from "../components/Auth";
import CalendarService from "../service/CalendarService";
import WayPoints from "../components/WayPoints";
import Logs from "./calendar/Logs";
import BookingEvent from "./calendar/BookingEvent";
import Tabs from "../components/form/Tabs";
import FittingEvent from "./calendar/FittingEvent";
import {Fitting} from "../components/form/model/Fitting";
import FittingService from "../service/FittingService";
import Select from "../components/form/Select";
import Access from "../components/Access";
import AvailabilityEvent from "./calendar/AvailabilityEvent";
import Runs from "./calendar/Runs";
import SortableList, {SortableItem} from 'react-easy-sort'
import arrayMove from 'array-move'
import HoldingCalendar from "./HoldingCalendar";


export default class Calendar extends React.Component {
    static propTypes = {
        date: PropTypes.any
    };

    minTime = '07:00:00';
    maxTime = '19:00:00';

    state = {
        reps: null,
        currentRep: 1,
        usersOfCurrentDate: []
    };

    curDate: Date = new Date();
    lastDate: Date = null;

    calendarItems: {
        events: EventItem[],
        user: User
    } = {};
    hoverEvent = null;

    modalStatusRep = {
        show: false
    };

    modalDeleteConfirm = {
        show: false
    };

    modalMap = {
        show: false
    };

    modalPopup = {
        show: false
    };

    modalRuns = {
        show: false
    };

    modalOutsideConfirm = {
        show: false
    };

    loading = 0;
    leaves = {};
    daysOff = [];
    holidays = [];

    selectedEvent: {
        user: User,
        booking: Booking,
        fitting: Fitting
    };
    bookingService: BookingService = new BookingService(() => {
        this.updateCalendar()
    });
    fittingService: FittingService = new FittingService(() => {
        this.updateCalendar()
    });

    reps: User[] = [];
    calendarRefs = [];
    workingTime: {} = {};

    // region contextMenu

    copiedEvent = null;
    triggeredEvent = null;
    deleteOnMove = false;

    contextTarget: ContextTarget = null;

    auth = new Auth();
    calendarService: CalendarService = new CalendarService();
    isSortingUsers: boolean = false;

    movedHoldingEvent = null;

    // endregion

    constructor(props) {
        super(props);

        this.refLoading = React.createRef();
        this.refContextMenu = React.createRef();

        this.onChangeShowRoom = this.onChangeShowRoom.bind(this);
        this.showBookingPopup = this.showBookingPopup.bind(this);
        this.onEventClick = this.onEventClick.bind(this);
        this.loadReps = this.loadReps.bind(this);
        this.updateCalendar = this.updateCalendar.bind(this);
        this.addCalendarItem = this.addCalendarItem.bind(this);
        this.onEventChangeTime = this.onEventChangeTime.bind(this);
        this.onMoveEventToOtherRep = this.onMoveEventToOtherRep.bind(this);
        this.loadingShow = this.loadingShow.bind(this);
        this.loadingHide = this.loadingHide.bind(this);
        this.getRepsWorkingTime = this.getRepsWorkingTime.bind(this);
        this.contextMenu = this.contextMenu.bind(this);
        this.onMouseEnterEvent = this.onMouseEnterEvent.bind(this);
        this.onMouseLeaveEvent = this.onMouseLeaveEvent.bind(this);
        this.onCopyEvent = this.onCopyEvent.bind(this);
        this.onPasteEvent = this.onPasteEvent.bind(this);
        this.findUser = this.findUser.bind(this);
        this.loadDayOffs = this.loadDayOffs.bind(this);
        this.searchCustomers = this.searchCustomers.bind(this);
        this.isWorkerOverlap = this.isWorkerOverlap.bind(this);
        this.onLoading = this.onLoading.bind(this);
        this.onChange = this.onChange.bind(this);
        this.deletingEvent = this.deletingEvent.bind(this);
        this.canShowUserCalendar = this.canShowUserCalendar.bind(this);
        this.onChangeBlinds = this.onChangeBlinds.bind(this);
        this.onDeleteEvent = this.onDeleteEvent.bind(this);
        this.getUserEventList = this.getUserEventList.bind(this);
        this.getBookingDefaultTab = this.getBookingDefaultTab.bind(this);

        this.auth.load();
        this.calendarService.load();
    }

    componentDidMount(): void {
        const filterTypes = this.getTypeList();
        let firstType = null;
        let found = false;

        filterTypes.forEach(item => {
            firstType = item.value;
            if (this.calendarService.calendarType === item.value) {
                found = true;
            }
        });

        if (!found) {
            this.calendarService.calendarType = firstType;
        }

        this.setState({loading: true});
        this.loadReps();
        this.loadData();
        this.getRepsWorkingTime();
    }

    loadingShow() {
        this.refLoading.current.style.display = 'block';
    }

    loadingHide() {
        this.refLoading.current.style.display = 'none';
    }

    componentDidUpdate(prevProps): void {
        this.loadData();

        if (this.props.date !== prevProps.date && this.state.usersOfCurrentDate.length > 0) {
            this.loadReps();
            this.setState({usersOfCurrentDate: []});
        }
    }

    checkDuplicateEvents(oldEvent) {
        if (!Array.isArray(this.calendarItems)) {
            return;
        }

        this.calendarItems.forEach((calItem, userId) => {
            if (!calItem) {
                return;
            }

            calItem.events.some((eventItem, key) => {
                if (eventItem.id === oldEvent.id) {
                    this.calendarItems[userId].events.splice(key, 1);
                    return true;
                }
                return false;
            })
        })
    }

    /**
     *  @return User|null
     */
    findUser(userId) {
        let user = null;

        this.reps.some(item => {
            if (item.id === parseInt(userId)) {
                user = item;

                return true;
            }

            return false;
        });

        return user;
    }

    sortCalendar(currentUserId, beforeUserId, afterUserId) {
        apiRequest('/bs/user/order', {
            userId: currentUserId,
            beforeUserId: beforeUserId,
            afterUserId: afterUserId,
        }).then(() => {
            notify({text: 'Calendar has been successfully sorted'});
        }).catch(response => {
            notify({text: response.message});
        });
    }

    doSortingMouseUp(oldIndex: number, newIndex: number, arr) {
        let allowedUsers = [];
        let currentUserId = null;
        let beforeUserId = null;
        let afterUserId = null;

        if (this.state.usersOfCurrentDate.length === 0) {
            arr.forEach(rep => {
                if (!this.canShowUserCalendar(rep)) {
                    return;
                }
                allowedUsers.push(rep);
            })
            this.setState({usersOfCurrentDate: allowedUsers});
        }
        let oldElemId = this.state.usersOfCurrentDate[oldIndex];
        let newElemId = this.state.usersOfCurrentDate[newIndex];
        let sortedItems = arrayMove(this.state.usersOfCurrentDate, oldIndex, newIndex);

        this.reps.forEach((rep, key) => {
            if (rep.id === oldElemId.id) {
                oldElemId = key;
            }
            if (rep.id === newElemId.id) {
                newElemId = key;
            }
        })

        this.reps = arrayMove(this.reps, oldElemId, newElemId);

        if (this.state.usersOfCurrentDate.length > 0) {

            sortedItems.forEach((user, userIndex) => {
                if (newIndex === userIndex) {
                    currentUserId = user.id;
                }
                if (userIndex === newIndex + 1) {
                    afterUserId = user.id;
                }
                if (userIndex === newIndex - 1) {
                    beforeUserId = user.id;
                }
            })
        }

        this.sortCalendar(currentUserId, beforeUserId, afterUserId);
        this.setState({usersOfCurrentDate: sortedItems});
    }

    getUserEvent(userId: number, eventElementId: number) {
        if (!this.calendarItems[userId]) {
            return null;
        }

        let eventIndex = null;

        this.calendarItems[userId].events.some((eventItem, i) => {
            if (eventItem.id === eventElementId) {
                eventIndex = i;

                return true;
            }

            return false;
        });

        return eventIndex !== null ? this.calendarItems[userId].events[eventIndex] : null;
    }

    searchCustomers(value, callback) {
        apiRequest('/bs/booking/search-customer', {
            search: value
        })
            .then(result => {
                if (!result || !result.custom) {

                    return;
                }

                let options = [];

                result.custom.forEach(item => {
                    item.value = item.id;
                    item.label = item.full_name + (item.phone ? ' ' + item.phone : '') + (item.email ? ' ' + item.email : '') + (item.address ? ' ' + item.address : '')
                        + (item.suburb ? ' ' + item.suburb : '') + (item.postal_code ? ' ' + item.postal_code : '');

                    options.push(item);
                });

                callback(options);
            });

        return [];
    }

    deleteUserEvent(userId: number, bookingId: number) {
        if (!this.calendarItems[userId]) {
            return;
        }

        let eventIndex = null;

        this.calendarItems[userId].events.some((eventItem, i) => {
            if (eventItem.id === bookingId) {
                eventIndex = i;

                return true;
            }

            return false;
        });

        if (eventIndex !== null) {
            this.calendarItems[userId].events.splice(eventIndex, 1);
        }
    }


    loadDayOffs() {
        apiRequest('/bs/day-off/', {
            date: moment(this.curDate).format('YYYY-MM-DD')
        })
            .then(result => {
                if (!result || !result.custom) {
                    this.daysOff = [];
                    this.updateCalendar();

                    return;
                }

                this.daysOff = result.custom;

                this.updateCalendar();
            });
    }

    loadHolidays() {
        apiRequest('/bs/user/get-holidays', {
            date: moment(this.curDate).format('YYYY-MM-DD')
        })
            .then(result => {
                if (!result || !result.custom) {
                    this.holidays = [];
                    this.updateCalendar();

                    return;
                }

                this.holidays = result.custom;

                this.updateCalendar();
            });
    }

    eventChangeUser(oldEvent: EventItem, newEvent: EventItem) {
        this.loadingShow();

        /**
         clean duplicate after item has moved from holding
         */
        this.checkDuplicateEvents(oldEvent);

        if (newEvent.extendedProps.booking) {
            this.bookingEventChangeUser(oldEvent, newEvent);
        } else {
            this.fittingEventChangeUser(oldEvent, newEvent);
        }
    }

    bookingEventChangeUser(oldEvent: EventItem, newEvent: EventItem) {
        let currentDate = moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD');

        apiRequest('/bs/booking/change-time', {
            id: newEvent.extendedProps.booking.id,
            repId: newEvent.extendedProps.user.id,
            date: oldEvent.extendedProps.user.isHolding ? currentDate : newEvent.extendedProps.booking.date,
            timeFrom: moment(newEvent.start).format('HH:mm') + ':00',
            timeTo: moment(newEvent.end).format('HH:mm') + ':00',
        }).then(() => {
            newEvent.extendedProps.booking.timeFrom = moment(newEvent.start).format('HH:mm') + ':00';
            newEvent.extendedProps.booking.timeTo = moment(newEvent.end).format('HH:mm') + ':00';

            this.addCalendarItem(newEvent.extendedProps.user.id, newEvent);

            if (oldEvent.extendedProps.user.isHolding) {
                this.movedHoldingEvent = {
                    userId: oldEvent.extendedProps.user.id,
                    calendarEventId: oldEvent.extendedProps.booking.id
                }
                newEvent.extendedProps.booking.date = currentDate;
            }

            this.loadingHide();

            this.setState({});
            this.checkDropOutTime(newEvent);

        }).catch(response => {
            this.loadingHide();
            notify({text: response.message});
        });
    }

    fittingEventChangeUser(oldEvent: EventItem, newEvent: EventItem) {
        let currentDate = moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD');

        apiRequest('/bs/fitting/change-time', {
            id: newEvent.extendedProps.fitting.id,
            userId: newEvent.extendedProps.user.id,
            oldUserId: oldEvent.extendedProps.user.id,
            oldUserList: oldEvent.extendedProps.fitting.userList,
            date: oldEvent.extendedProps.user.isHolding ? currentDate : newEvent.extendedProps.fitting.date,
            timeFrom: moment(newEvent.start).format('HH:mm') + ':00',
            timeTo: moment(newEvent.end).format('HH:mm') + ':00',
        }).then(() => {
            newEvent.extendedProps.fitting.timeFrom = moment(newEvent.start).format('HH:mm') + ':00';
            newEvent.extendedProps.fitting.timeTo = moment(newEvent.end).format('HH:mm') + ':00';

            if (oldEvent.extendedProps.user.isHolding) {
                this.movedHoldingEvent = {
                    userId: oldEvent.extendedProps.user.id,
                    calendarEventId: 'fi' + oldEvent.extendedProps.fitting.id
                }
                newEvent.extendedProps.fitting.date = currentDate;
            }

            this.addCalendarItem(newEvent.extendedProps.user.id, newEvent);

            this.loadingHide();

            this.setState({});
            this.checkDropOutTime(newEvent);

        }).catch(response => {
            this.loadingHide();
            notify({text: response.message});
        });
    }

    bookingEventChangeTime(event) {
        apiRequest('/bs/booking/change-time', {
            id: event.extendedProps.booking.id,
            repId: event.extendedProps.user.id,
            date: event.extendedProps.booking.date,
            timeFrom: moment(event.start).format('HH:mm') + ':00',
            timeTo: moment(event.end).format('HH:mm') + ':00',
        }).then(() => {
            let testEvent = this.getUserEvent(event.extendedProps.user.id, event.extendedProps.booking.id);

            if (testEvent) {
                testEvent.start = event.extendedProps.booking.date + ' ' + moment(event.start).format('HH:mm') + ':00';
                testEvent.end = event.extendedProps.booking.date + ' ' + moment(event.end).format('HH:mm') + ':00';

                testEvent.extendedProps.booking.timeFrom = moment(event.start).format('HH:mm') + ':00';
                testEvent.extendedProps.booking.timeTo = moment(event.end).format('HH:mm') + ':00';
            }

            this.loadingHide();
            this.checkDropOutTime(testEvent);
        }).catch(response => {
            this.loadingShow();
            notify({text: response.message});
        });
    }

    fittingEventChangeTime(event) {
        this.loadingShow();

        apiRequest('/bs/fitting/change-time', {
            id: event.extendedProps.fitting.id,
            userId: event.extendedProps.user.id,
            date: event.extendedProps.fitting.date,
            timeFrom: moment(event.start).format('HH:mm') + ':00',
            timeTo: moment(event.end).format('HH:mm') + ':00',
        }).then(() => {
            let testEvent = this.getUserEvent(event.extendedProps.user.id, 'fi' + event.extendedProps.fitting.id);

            if (testEvent) {
                testEvent.start = event.extendedProps.fitting.date + ' ' + moment(event.start).format('HH:mm') + ':00';
                testEvent.end = event.extendedProps.fitting.date + ' ' + moment(event.end).format('HH:mm') + ':00';

                testEvent.extendedProps.fitting.timeFrom = moment(event.start).format('HH:mm') + ':00';
                testEvent.extendedProps.fitting.timeTo = moment(event.end).format('HH:mm') + ':00';
            }

            this.loadingHide();
            this.checkDropOutTime(testEvent);
        }).catch(response => {
            this.loadingShow();
            notify({text: response.message});
        });
    }

    onMoveEventToOtherRep(info, newUser: User) {
        let newEvent = new EventItem();
        newEvent.id = info.event.id;
        newEvent.title = info.event.title;
        newEvent.constraint = info.event.constraint;

        newEvent.className = info.event.classNames.join(' ');

        if (info.event.extendedProps.fitting && info.event.extendedProps.fitting.userList.length > 1) {
            info.revert();
            return;

            // const fitter = info.event.extendedProps.fitting;
            // newEvent.date = moment(fitter.date).toDate();
            // newEvent.start = moment(fitter.date + ' ' + fitter.timeFrom).format('YYYY-MM-DD HH:mm:00');
            // newEvent.end = moment(fitter.date + ' ' + fitter.timeTo).format('YYYY-MM-DD HH:mm:00');
        } else {
            newEvent.date = this.curDate;
            newEvent.start = moment(info.event.start).format('YYYY-MM-DD HH:mm:00');
            newEvent.end = moment(info.event.end).format('YYYY-MM-DD HH:mm:00');
        }

        newEvent.extendedProps = {
            booking: info.event.extendedProps.booking,
            fitting: info.event.extendedProps.fitting,
            user: newUser,
        };

        this.eventChangeUser(info.event, newEvent);
    }

    getRepsWorkingTime() {
        this.loading++;

        apiRequest('/bs/user/get-reps-schedule-all')
            .then(data => {
                this.workingTime = {};

                if (data && data.custom) {
                    data.custom.forEach(row => {
                        if (!this.workingTime[row.rep_id]) {
                            this.workingTime[row.rep_id] = {};
                        }

                        if (!this.workingTime[row.rep_id][row.day]) {
                            this.workingTime[row.rep_id][row.day] = {
                                from: row.time_from,
                                to: row.time_to,
                                enabled: row.status === 1
                            };
                        }
                    });
                }

                this.updateCalendar();

            }).catch(response => {
            this.loadingHide();
            notify({text: response.message});
        });
    }

    onEventChangeTime(info) {
        if (info.event.extendedProps.booking) {
            this.loadingShow();
            this.bookingEventChangeTime(info.event);

            return;
        }

        // prevent multifitting time change
        if (info.event.extendedProps.fitting.userList.length > 1) {
            info.revert();
            return;
        }

        this.loadingShow();
        this.fittingEventChangeTime(info.event);
    }

    addCalendarItem(userId, event: EventItem) {
        let user = this.findUser(userId);

        if (!user) {
            console.warn(['Can not add event for user ' + userId, event]);

            return;
        }

        if (!this.calendarItems[user.id]) {
            this.calendarItems[user.id] = {
                user: user,
                events: []
            };
        }

        if (!event.extendedProps) {
            event.extendedProps = {};
        }
        event.extendedProps.user = user;

        if (!this.calendarItems[user.id].events.find(addedEv => addedEv.id === event.id)) {
            this.calendarItems[user.id].events.push(event);
        }
    }

    updateCalendar() {
        this.loading--;

        if (this.loading > 0) {
            return;
        }

        this.calendarItems = [];

        // not working
        this.reps.forEach(item => {
            let user = this.findUser(item.id);

            if (user) {
                user.available = true;
                user.isOverlap = false;
            }

            if (!this.workingTime[item.id] || !this.workingTime[item.id][moment(this.curDate).format('d')]
                || !this.workingTime[item.id][moment(this.curDate).format('d')].enabled) {

                if (user) {
                    user.available = false;
                }

                this.addCalendarItem(item.id, {
                    id: 'off' + item.id,
                    title: 'Unavailable',
                    start: moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD 07:00:00'),
                    end: moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD 19:00:00'),
                    className: 'unavailable',
                    // editable: false,
                    extendedProps: {
                        type: 'not-working'
                    },
                    fromHolding: false,
                    // rendering: 'background'
                });
            }
        });


        // add day off to calendar
        this.daysOff.forEach(item => {
            let workingDayTime;

            if (this.workingTime[item.user_id] && this.workingTime[item.user_id][moment(this.curDate).format('d')]) {
                workingDayTime = this.workingTime[item.user_id][moment(this.curDate).format('d')];
            }

            let event = {
                id: 'off' + item.id,
                title: 'Unavailable',
                className: 'unavailable__date',
                start: moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD ' + (item.start_time ? item.start_time : (workingDayTime ? workingDayTime.from : '07:00:00'))),
                end: moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD ' + (item.end_time ? item.end_time : (workingDayTime ? workingDayTime.to : '19:00:00'))),
                editable: false,
                extendedProps: {
                    type: 'not-working'
                },
                rendering: 'background'
            }

            if (parseInt(item.event_type) === AVAILABILITY_EVENT_TYPE.meeting) {
                event.title = `Meeting | ${item.message ?? ""}`;
                event.className = 'meeting';
            }

            this.addCalendarItem(item.user_id, event, true);
        });

        // add holidays off to calendar
        this.holidays.forEach(item => {
            this.reps.forEach(user => {
                this.addCalendarItem(user.id, {
                    id: 'hol' + item.id,
                    title: item.name,
                    start: moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD 07:00:00'),
                    end: moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD 19:00:00'),
                    className: 'public__holiday',
                    editable: false,
                    extendedProps: {
                        type: 'public-holiday'
                    },
                    rendering: 'background'
                });

            });
        });

        // add loaded bookings to calendar
        this.bookingService.list.forEach(booking => {
            let user = this.findUser(booking.userId);
            const timeFrom = moment(booking.date + ' ' + booking.timeFrom);
            const timeTo = moment(booking.date + ' ' + booking.timeTo);

            if (user && !user.isOverlap) {
                user.isOverlap = this.isWorkerOverlap(user, timeFrom, timeTo);
            }

            let title = (booking.company ? booking.company : booking.firstName + ' ' + booking.lastName) + ' | '
                + booking.suburb + ' | ' + booking.getMeasuringsString();

            if (this.calendarService.calendarType === 'fitter') {
                title += ' | ' + booking.message;
            }

            this.addCalendarItem(booking.userId, {
                title: title,
                id: booking.id,
                extendedProps: {
                    booking: booking
                },
                start: booking.date + ' ' + booking.timeFrom,
                end: booking.date + ' ' + booking.timeTo,
                className: booking.howToKnow === 14 ? 'self__booking' : '',
                fromHolding: false,
            });
        });
        // add loaded fitting to calendar
        this.fittingService.list.forEach(fitter => {
            let user = this.findUser(fitter.userId);

            if (user && !user.isOverlap) {
                const timeFrom = moment(fitter.date + ' ' + fitter.timeFrom);
                const timeTo = moment(fitter.date + ' ' + fitter.timeTo);
                user.isOverlap = this.isWorkerOverlap(user, timeFrom, timeTo);
            }

            let className = fitter.fixedTime ? 'fixed__time' : '';
            const users = fitter.userList ? fitter.userList : [fitter.userId];

            if (users.length > 1) {
                className += ' multiple__fitter';
            }

            let title = (fitter.booking.company ? fitter.booking.company : fitter.booking.firstName + ' ' + fitter.booking.lastName);

            if (fitter.productList.length || fitter.productList.length === 0) {
                let tp;

                if (fitter.productList[0]) {
                    tp = fitter.productList[0].type_name;
                }

                switch (!tp ? '' : fitter.productList[0].type_name) {
                    case 'Check measure':
                        className += ' fitting__type__cm';
                        break;

                    case 'Service call':
                        className += ' fitting__type__sc ';
                        break;

                    case 'Fitting':
                        className += ' fitting__type__fitting ';
                        break;

                    case 'JP':
                        className += ' fitting__type__jp ';
                        break;

                    default:
                        className += ' fitting__type__sc';
                }

                if (fitter.productList.length === 0) {
                    tp = "Service Call"
                }

                if (tp === 'Check measure') {
                    tp = 'CM';
                }

                title += ' | ' + tp;
            }

            title += ', ' + fitter.booking.suburb + ' | ' + fitter.productToString();

            if (this.calendarService.calendarType === 'fitter') {
                title += ' | ' + fitter.notes;
            }

            let eventItem = {
                title: title,
                id: 'fi' + fitter.id,
                extendedProps: {
                    fitting: fitter
                },
                start: fitter.date + ' ' + fitter.timeFrom,
                end: fitter.date + ' ' + fitter.timeTo,
                className: className,
                fromHolding: false,
            };

            if (users.length > 1) {
                // eventItem.constraint = {
                //     startTime: fitter.date + ' ' + fitter.timeFrom,
                //     endTime: fitter.date + ' ' + fitter.timeTo,
                // };
                eventItem.editable = false;
            }

            users.forEach(userId => {
                this.addCalendarItem(userId, eventItem);
            });

        });

        // add leaves to calendar
        this.leaves.forEach(item => {
            let user = this.findUser(item.id);
            user.available = false;

            this.addCalendarItem(item.user_id, {
                id: 'an' + item.id,
                title: 'Annual Leave',
                start: moment(item.date_from, "YYYY-MM-DD").format('YYYY-MM-DD 07:00:00'),
                end: moment(item.date_to, "YYYY-MM-DD").format('YYYY-MM-DD 19:00:00'),
                className: 'annual-leave',
                editable: false
            });
        });

        // all calendars goto date
        this.calendarRefs.forEach((userId, ref) => {
            if (!ref || !ref.current) {
                return;
            }

            let calendar = ref.current.getApi();
            calendar.gotoDate(this.curDate);
        });

        this.loadingHide();
        this.setState({});
    }

    isWorkerOverlap(user: User, fromTime, toTime): boolean {
        if (!this.workingTime[user.id] || !this.workingTime[user.id][moment(this.curDate).format('d')]
            || !this.workingTime[user.id][moment(this.curDate).format('d')].enabled) {

            return true;
        }

        const workingTime = this.workingTime[user.id][moment(this.curDate).format('d')];
        const workingTimeFrom = moment(moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD ' + workingTime.from));
        const workingTimeTo = moment(moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD ' + workingTime.to));


        return toTime.diff(workingTimeTo) > 0 || fromTime.diff(workingTimeFrom) < 0;
    }

    loadData() {
        if (moment(this.lastDate).format('YYYY-MM-DD') === moment(this.curDate).format('YYYY-MM-DD')) {
            return;
        }

        this.lastDate = this.curDate;
        this.loadingShow();

        this.loading += 5;

        this.getLeaves();
        this.loadDayOffs();
        this.loadHolidays();

        this.bookingService
            .getPagination()
            .setPageSize(1200);

        this.bookingService.setSearchParams({
            status: [BOOKING_STATUS.active],
            dateFrom: moment(this.curDate).format('YYYY-MM-DD'),
            dateTo: moment(this.curDate).format('YYYY-MM-DD')
        });

        this.fittingService
            .getPagination()
            .setPageSize(1200);

        this.fittingService.setSearchParams({
            status: [BOOKING_STATUS.active],
            dateFrom: moment(this.curDate).format('YYYY-MM-DD'),
            dateTo: moment(this.curDate).format('YYYY-MM-DD')
        });
    }

    contextMenu(e) {
        e.preventDefault();

        let hideItems = [];

        if (!this.hoverEvent || this.hoverEvent.event.extendedProps.type === 'not-working') {
            hideItems.push('cut');
        }

        if (!this.hoverEvent || this.hoverEvent.event.extendedProps.type !== 'not-working') {
            hideItems.push('delete');
        }

        if (!this.copiedEvent || (this.hoverEvent && this.hoverEvent.event.extendedProps.type === 'not-working')) {
            hideItems.push('paste');
        }

        if (hideItems.length === 3) {
            return;
        }

        this.triggeredEvent = this.hoverEvent;

        let targetTime = e.target.dataset.time;

        let user = null;
        let searchEl = e.target.parentElement;
        let maxDeep = 10000;

        while (true) {
            if (!searchEl) {
                break;
            }

            if (searchEl.classList.contains('calendar__booking__list')) {
                user = searchEl.dataset.id;
                break;
            }

            searchEl = searchEl.parentElement;
            maxDeep--;

            if (maxDeep === 0) {
                break;
            }
        }

        if (user) {
            this.contextTarget = {
                type: ContextTarget.TYPE_CELL,
                userId: Number(user),
                time: targetTime,
            };
        } else if (this.triggeredEvent) {
            this.contextTarget = {
                type: ContextTarget.TYPE_EVENT,
                event: this.triggeredEvent,
            };
        } else {
            this.contextTarget = {
                type: ContextTarget.TYPE_UNKNOWN
            };
        }

        let menu = this.refContextMenu.current;

        const resultWidth = e.pageX + menu.getWidth();
        const positionFixX = window.scrollX + window.innerWidth < resultWidth ? window.scrollX + window.innerWidth - resultWidth - 50 : 0;

        menu.hideItems(hideItems);
        menu.show(e.pageX + positionFixX, e.pageY);
    }

    onCopyEvent(deleteOnMove: boolean) {
        if (!this.triggeredEvent) {
            console.log('NO TRIG');
            return;
        }

        this.triggeredEvent.el.style.opacity = 0.5;

        this.deleteOnMove = deleteOnMove;
        this.copiedEvent = this.getUserEvent(
            this.triggeredEvent.event.extendedProps.user.id,
            this.triggeredEvent.event.extendedProps.booking ? this.triggeredEvent.event.extendedProps.booking.id : 'fi' + this.triggeredEvent.event.extendedProps.fitting.id
        );
    }

    onPasteEvent() {
        if (!this.copiedEvent) {
            return;
        }

        let start = moment(this.copiedEvent.start);
        let end = moment(this.copiedEvent.end);
        let targetStart = moment(moment(this.curDate).format('YYYY-MM-DD') + ' ' + this.contextTarget.time);

        let newEvent = new EventItem();
        newEvent.id = this.copiedEvent.id;
        newEvent.title = this.copiedEvent.title;
        newEvent.date = this.curDate;

        newEvent.className = this.copiedEvent.className;
        newEvent.start = targetStart.format('YYYY-MM-DD HH:mm:00');

        targetStart.add(moment.duration(end.diff(start)).asMilliseconds(), 'milliseconds');

        newEvent.end = targetStart.format('YYYY-MM-DD HH:mm:00');
        newEvent.extendedProps = {
            booking: this.copiedEvent.extendedProps.booking,
            fitting: this.copiedEvent.extendedProps.fitting,
            user: this.findUser(this.contextTarget.userId)
        };

        if (newEvent.extendedProps.booking) {
            newEvent.extendedProps.booking.date = moment(newEvent.date).format('YYYY-MM-DD');
        } else {
            if (newEvent.extendedProps.fitting.userList.length > 1) {
                newEvent.date = moment(newEvent.extendedProps.fitting.date).toDate();
                newEvent.start = moment(newEvent.extendedProps.fitting.date + ' ' + newEvent.extendedProps.fitting.timeFrom).format('YYYY-MM-DD HH:mm:00');
                newEvent.end = moment(newEvent.extendedProps.fitting.date + ' ' + newEvent.extendedProps.fitting.timeTo).format('YYYY-MM-DD HH:mm:00');
                newEvent.constraint = this.copiedEvent.constraint;
            } else {
                newEvent.extendedProps.fitting.date = moment(newEvent.date).format('YYYY-MM-DD');
            }
        }

        if (this.deleteOnMove) {
            this.eventChangeUser(this.copiedEvent, newEvent);
        }

        this.copiedEvent = null;
        this.contextTarget = null;
    }

    onMouseEnterEvent(info) {
        // if (info.event && info.event.extendedProps.type === 'not-working') {
        //     return;
        // }

        this.hoverEvent = info;
    }

    onMouseLeaveEvent() {
        this.hoverEvent = null;
    }

    onEventClick(info) {
        if (info.event && info.event.extendedProps.type === 'not-working') {
            return;
        }

        if (info.event.id.substr(0, 2) === 'an') {
            return;
        }

        // this.selectedEvent = info.event.extendedProps;
        this.selectedEvent = this.getUserEvent(info.event.extendedProps.user.id, info.event.id).extendedProps;

        this.showBookingPopup(true);
    }

    onDateClick(info, user) {
        if (info.event && info.event.extendedProps.type === 'not-working') {
            return;
        }

        this.selectedEvent = {
            user: user,
            booking: new Booking(),
            fitting: new Fitting()
        };

        let date = new moment(info.date);

        this.selectedEvent.booking.date = date.format('YYYY-MM-DD');
        this.selectedEvent.booking.timeFrom = date.format('HH:mm') + ':00';
        this.selectedEvent.booking.timeTo = new moment(info.date).add(2, 'hours').format('HH:mm') + ':00';

        this.selectedEvent.fitting.date = date.format('YYYY-MM-DD');
        this.selectedEvent.fitting.timeFrom = date.format('HH:mm') + ':00';
        this.selectedEvent.fitting.timeTo = new moment(info.date).add(1.5, 'hours').format('HH:mm') + ':00';
        this.showBookingPopup(true);
    }

    showBookingPopup(show: boolean): void {
        this.modalStatusRep.show = show;

        this.setState({});
    }

    getLeaves() {
        let pagination = new Pagination();
        pagination.pageSize = 200;

        apiRequest(
            '/bs/user/get-leaves',
            {
                date: moment(this.curDate).format('YYYY-MM-DD'),
                pagination: pagination
            }
        ).then(result => {
            if (!result || !result.custom) {
                this.leaves = [];
                this.updateCalendar();

                return;
            }

            this.leaves = result.custom;

            this.updateCalendar();
        })
    }

    onChangeShowRoom(event) {
        this.calendarService.showRooms[event.target.value] = event.target.checked;
        this.calendarService.save();

        this.setState({usersOfCurrentDate: []});
    }

    onChangeBlinds(event) {
        this.calendarService.blinds[event.target.value] = event.target.checked;
        this.calendarService.save();

        this.setState({usersOfCurrentDate: []});
    }

    loadReps() {
        this.loadingShow();
        this.loading++;

        apiRequest('/bs/user/reps-order')
            .then(data => {
                if (data.custom) {
                    this.reps = [];
                    data.custom.forEach(item => {
                        let user = new User();
                        user.assign(item);

                        this.reps.push(user);
                        this.calendarRefs[user.id] = React.createRef();
                    });

                    this.updateCalendar();
                }
            }).catch(response => {
            notify({text: response.message});
        });
    }

    getUserPointAddresses() {
        if (!this.mapSelectedUser) {
            return [];
        }

        if (!this.calendarItems[this.mapSelectedUser]) {
            return [];
        }

        let items = [];

        let sortLineNum = [];

        this.calendarItems[this.mapSelectedUser].events.forEach((item) => {
            if (item.extendedProps.booking) {
                sortLineNum.push(item.extendedProps.booking.dateCreated);

                items.push({
                    addr: item.extendedProps.booking.address + ', ' + item.extendedProps.booking.suburb + ', ' + item.extendedProps.booking.postalCode,
                    date: moment(item.extendedProps.booking.date + ' ' + item.extendedProps.booking.timeFrom),
                    info: `#${item.id}, ${moment(item.extendedProps.booking.dateCreated).format('DD MMMM YYYY, hh:mma')}`,
                    infoDate: item.extendedProps.booking.dateCreated
                });
            } else if (item.extendedProps.fitting) {
                sortLineNum.push(item.extendedProps.fitting.dateAdded);

                items.push({
                    addr: item.extendedProps.fitting.booking.address + ', ' + item.extendedProps.fitting.booking.suburb + ', ' + item.extendedProps.fitting.booking.postalCode,
                    date: moment(item.extendedProps.fitting.date + ' ' + item.extendedProps.fitting.timeFrom),
                    info: `#${item.id.replace('fi', '')}(fitting), ${moment(item.extendedProps.fitting.dateAdded).format('DD MMMM YYYY, hh:mma')}`,
                    infoDate: item.extendedProps.fitting.dateAdded
                });
            }
        });

        items.sort(function (a, b) {
            return a.date > b.date ? 1 : -1;
        });

        sortLineNum.sort(function (a,b) {
            return a > b ? 1 : -1;
        });

        let result = [];

        items.forEach(item => {
            result.push({
                addr: item.addr,
                info: item.info,
                infoDate: item.infoDate,
                sortLineNum: sortLineNum
            });
        });

        return result;
    }

    getUserEventList() {
        if (!this.mapSelectedUser) {
            return [];
        }

        if (!this.calendarItems[this.mapSelectedUser]) {
            return [];
        }

        let items = [];

        this.calendarItems[this.mapSelectedUser].events.forEach((item) => {
            if (item.extendedProps.booking) {
                items.push({
                    item: item.extendedProps.booking,
                    date: moment(item.extendedProps.booking.date + ' ' + item.extendedProps.booking.timeFrom)
                });
            } else if (item.extendedProps.fitting) {
                items.push({
                    item: item.extendedProps.fitting,
                    date: moment(item.extendedProps.fitting.date + ' ' + item.extendedProps.fitting.timeFrom)

                });
            }
        });

        items.sort(function (a, b) {
            return a.date > b.date ? 1 : -1;
        });

        let result = [];

        items.forEach(item => {
            result.push(item.item);
        });

        return result;
    }

    onLoading(show) {
        if (show) {
            this.loadingShow();
        } else {
            this.loadingHide();
        }
    }

    onChange() {
        this.showBookingPopup(false);
        this.loadingShow();
        this.loading += 3;
        this.daysOff = [];

        this.bookingService
            .resetPagination()
            .loadList();

        this.fittingService
            .resetPagination()
            .loadList();

        this.loadDayOffs();
    }

    canShowUserCalendar(user: User) {
        if (user.isHolding) {
            return false;
        }

        if (this.calendarService.hideUnavailable && !user.available) {
            return false;
        }
        // consultant|reps
        if (this.calendarService.calendarType === 'consultant') {
            if (user.role !== ROLES.reps.toString()) {
                return false;
            }

            if (!user.showroom) {
                return true;
            }

            // showroom match
            if (!this.calendarService.showRooms[user.showroom]) {
                return false;
            }

            return true;
        }

        // fitters
        if (this.calendarService.calendarType === 'fitter') {
            if (user.role !== ROLES.fitter.toString()) {
                return false;
            }

            let canShow = false;

            MEASUREMENTS_SELECT.some((item) => {
                if (user.measuringHas(item.value.toString()) && this.calendarService.blinds[item.value]) {
                    canShow = true;

                    return true;
                }

                return false;
            });

            return canShow;
        }

        return false;
    }

    onDeleteEvent() {
        if (!this.triggeredEvent) {
            console.log('NO TRIG');
            return;
        }

        apiRequest('/bs/day-off/delete', {
            id: this.triggeredEvent.event.id.substr(3)
        })
            .then(() => {
                this.triggeredEvent = null;
                this.modalDeleteConfirm.show = false;

                this.onChange();
            }).catch(response => {
            notify({text: response.message});
        });

    }

    getTypeList() {
        let result = [];

        if (Access.isAllowed('Calendar', 'typeSwitchConsultant')) {
            result.push({
                value: 'consultant',
                label: 'Consultant'
            });
        }

        if (Access.isAllowed('Calendar', 'typeSwitchFitter')) {
            result.push({
                value: 'fitter',
                label: 'Fitter'
            });
        }

        return result;
    }

    checkDropOutTime(event) {
        let timeFrom, timeTo;
        this.movedHoldingEvent = null;

        if (event.extendedProps.booking) {
            timeFrom = moment(event.extendedProps.booking.date + ' ' + event.extendedProps.booking.timeFrom);
            timeTo = moment(event.extendedProps.booking.date + ' ' + event.extendedProps.booking.timeTo);
        } else {
            timeFrom = moment(event.fromHolding ? moment(this.curDate, "YYYY-MM-DD") : event.extendedProps.fitting.date + ' ' + event.extendedProps.fitting.timeFrom);
            timeTo = moment(event.fromHolding ? moment(this.curDate, "YYYY-MM-DD") : event.extendedProps.fitting.date + ' ' + event.extendedProps.fitting.timeTo);
        }

        if (this.isWorkerOverlap(event.extendedProps.user, timeFrom, timeTo)) {
            event.extendedProps.user.isOverlap = true;
            this.modalOutsideConfirm.show = true;
            this.setState({})
        } else {
            event.extendedProps.user.isOverlap = false;
            this.setState({})
        }
    }

    getBookingDefaultTab() {
        if (this.selectedEvent.booking && this.selectedEvent.booking.id) {
            return 0;
        }

        if ((this.selectedEvent.fitting && this.selectedEvent.fitting.id)
            // || this.auth.role === ROLES.commercial
            || this.calendarService.calendarType === 'fitter'
        ) {
            return 1;
        }

        return 0;
    }

    eventReceive(userId, eventId) {
        this.deleteUserEvent(userId, eventId);
    }

    togglePopupModal() {
        const asideCalendar = document.querySelector(`.calendar__box`);
        asideCalendar.style.display = 'none';

        this.modalPopup.show = !this.modalPopup.show;
        this.setState({});
    }

    showAsideCalendar() {
        const asideCalendar = document.querySelector(`.calendar__box`);
        asideCalendar.style.display = 'inherit';
    }


    /** @deletingEvent - delete booking/fitting event without re-render calendar
     */
    deletingEvent() {
        let canceledItem = null;

        if (this.selectedEvent) {
            this.calendarItems[this.selectedEvent.user.id].events.some((event, key) => {

                if (this.selectedEvent.booking && event.id === this.selectedEvent.booking.id) {
                    canceledItem = key;
                    return true;
                }

                if (this.selectedEvent.fitting && event.id === 'fi' + this.selectedEvent.fitting.id) {
                    canceledItem = key;
                    return true;
                }
                return false;
            })

            if (canceledItem !== null) {
                this.calendarItems[this.selectedEvent.user.id].events.splice(canceledItem, 1);
            }
        }

        this.showBookingPopup(false);
        this.loadingHide();
        this.setState({});
    }

    render() {
        if (this.props.date) {
            this.curDate = this.props.date.date;
        }
        let wereItemsSorted = this.state.usersOfCurrentDate.length !== 0 ? this.state.usersOfCurrentDate : this.reps;

        return (
            <>
                <div className="loading__overlay" ref={this.refLoading} style={{display: 'none'}}>
                    <svg className="icon__loading">
                        <circle cx="13" cy="13" r="13"></circle>
                    </svg>
                </div>
                <h1>Calendar
                    <span className="icon__hold" onClick={() => this.togglePopupModal()}>
                        <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1102_1235)">
                            <path
                                d="M4.5 11.55V7.03001C4.5 4.54001 0.720001 4.69001 0.720001 7.38001V13.35L0.990001 20.86C1.17 26.15 13.11 27.59 15.99 23.11L21.69 16.03C22.27 15.16 21.99 14.01 21.08 13.46C20.01 13.47 18.49 13.63 17.82 14.44L15.8 16.89L15.84 4.48001C15.84 2.31001 12.19 1.76001 12.14 4.53001L12.03 10.29"
                                stroke="#010101" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.21 10.37L8.19 2.54999C8.19 0.379991 11.99 -0.170009 12 2.59999L12.04 10.19"
                                  stroke="#010101" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.48 7.07999V4.38999C4.46 2.21999 8.12 1.66999 8.19 4.43999" stroke="#010101"
                                  strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.24 11.57C9.7 10.79 6.27 10.9 3 12.29" stroke="#010101" strokeWidth="1.4"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M15.8 16.9C12.59 17.09 10.57 18.26 9.73999 20.49" stroke="#010101"
                                  strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1102_1235">
                            <rect width="22.72" height="26.5" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        
                    </span>
                </h1>
                <div className="panel">
                    <div className="panel__body panel__body--no-padding">
                        <div className="calendar__actions">
                            <div className="calendar__filter item">
                                {this.calendarService.calendarType === 'consultant' ?
                                    <BoxGroup inline={true}>
                                        {Object.entries(SHOWROOMS).map(([key, showRoomName]) => {
                                            if (!this.auth.showrooms.includes(key) && this.auth.role !== ROLES.admin) {
                                                return;
                                            }

                                            return (
                                                <CheckBox
                                                    onChange={this.onChangeShowRoom}
                                                    value={key}
                                                    key={'rep-vis-' + key}
                                                    checked={this.calendarService.showRooms[key]}
                                                >{showRoomName}</CheckBox>
                                            )
                                        })}
                                    </BoxGroup>
                                    :
                                    <BoxGroup inline={true}>
                                        {MEASUREMENTS_SELECT.map((item, key) => {
                                            if (!this.auth.blinds.includes(item.value.toString()) && this.auth.role !== ROLES.admin) {
                                                return;
                                            }

                                            return (
                                                <CheckBox
                                                    onChange={this.onChangeBlinds}
                                                    value={item.value}
                                                    key={'fit-vis-' + item.value}
                                                    checked={this.calendarService.blinds && this.calendarService.blinds[item.value]}
                                                >{item.label}</CheckBox>
                                            )
                                        })}
                                    </BoxGroup>
                                }
                            </div>
                            <div className="item">

                                <CheckBox
                                    inline={true}
                                    onChange={() => {
                                        this.calendarService.hideUnavailable = !this.calendarService.hideUnavailable;
                                        this.calendarService.save();
                                        this.setState({});
                                    }}
                                    defaultChecked={this.calendarService.hideUnavailable}
                                >Hide unavailable</CheckBox>


                            </div>

                            <div className="item calendar__filter--type">
                                <Select
                                    label=""
                                    size="small"
                                    options={this.getTypeList()}
                                    defaultValue={this.calendarService.calendarType}
                                    onChange={(e) => {
                                        this.calendarService.calendarType = e.target.value;
                                        this.calendarService.save();
                                        this.setState({});
                                    }}
                                />

                            </div>
                            <div className="item calendar__filter--wide">
                                <CheckBox
                                    inline={true}
                                    onChange={(e) => {
                                        this.calendarService.isWide = e.target.checked;
                                        this.calendarService.save();
                                        this.setState({});
                                    }}
                                    defaultChecked={this.calendarService.isWide}
                                >Wide layout</CheckBox>
                            </div>

                            {this.auth.role === ROLES.admin &&
                                <div className="item calendar__filter--wide">
                                    <CheckBox
                                        inline={true}
                                        onChange={(e) => {
                                            this.isSortingUsers = e.target.checked;
                                            this.setState({});
                                        }}
                                        defaultChecked={false}
                                    >Sort Users</CheckBox>
                                </div>}

                        </div>

                        <div className="calendar__booking">
                            <div
                                className="calendar__booking__row"
                                onContextMenu={this.contextMenu}
                            >
                                <SortableList
                                    onSortEnd={(oldIndex, newIndex) => this.doSortingMouseUp(oldIndex, newIndex, this.reps)}
                                    className="list"
                                    allowDrag={this.isSortingUsers}>

                                    {wereItemsSorted.map((item, key) => {
                                        if (!this.canShowUserCalendar(item)) {
                                            return '';
                                        }

                                        let workingDayTime;

                                        if (this.workingTime[item.id] && this.workingTime[item.id][moment(this.curDate).format('d')]) {
                                            workingDayTime = this.workingTime[item.id][moment(this.curDate).format('d')];
                                        }

                                        return (
                                            <SortableItem key={key} data-id={item.id}>
                                                <div
                                                    className={'calendar__booking__list' + (this.calendarService.isWide ? ' calendar__booking__list--wide' : '')}
                                                    key={key + item.id} data-id={item.id}>
                                                    <div
                                                        className="calendar__booking__reps__name"
                                                    ><span className="firstname">{item.firstName}</span> <span
                                                        className="lastname">{item.lastName}</span><span
                                                        className={'working-time ' + (item.isOverlap ? 'overlap' : '')}>{workingDayTime && workingDayTime.enabled ?
                                                        moment(moment(this.curDate).format('YYYY-MM-DD') + ' ' + workingDayTime.from).format('h:mm')
                                                        + ' - ' + moment(moment(this.curDate).format('YYYY-MM-DD') + ' ' + workingDayTime.to).format('h:mm')
                                                        : 'n/a'}</span>
                                                        <div className="action">
                                                    <span className="action__link"
                                                          onClick={() => {
                                                              this.mapSelectedUser = item.id;
                                                              this.modalMap.show = true;
                                                              this.setState({})
                                                          }}
                                                    >Route</span> | <span className="action__link"
                                                                          onClick={() => {
                                                                              this.mapSelectedUser = item.id;
                                                                              this.modalRuns.show = true;
                                                                              this.setState({})
                                                                          }}
                                                        >Runs</span>
                                                        </div>
                                                    </div>
                                                    <FullCalendar
                                                        key={Math.random()}
                                                        initialView={'timeGridDay'}
                                                        headerToolbar={{
                                                            left: '',
                                                            center: '',
                                                            right: ''
                                                        }}
                                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                        // weekends={this.state.calendarWeekends}
                                                        events={this.calendarItems[item.id] ? this.calendarItems[item.id].events : null}
                                                        eventClick={this.onEventClick}
                                                        dateClick={(info) => {
                                                            this.onDateClick(info, item)
                                                        }}
                                                        slotMinTime={this.minTime}
                                                        slotMaxTime={this.maxTime}
                                                        droppable={"true"}
                                                        editable={"true"}
                                                        allDaySlot={false}
                                                        dayHeaders={false}
                                                        // snapDuration={'00:01:00'}

                                                        eventReceive={(info) => this.onMoveEventToOtherRep(info, item)}
                                                        eventDrop={this.onEventChangeTime}

                                                        // id={item.id}
                                                        contentHeight={"auto"}
                                                        eventDurationEditable={false}
                                                        // repMinTime={reps.rep_time_from}
                                                        // repMaxTime={reps.rep_time_to}
                                                        // timing={reps.timing}
                                                        initialDate={this.curDate}
                                                        ref={this.calendarRefs[item.id]}
                                                        eventMouseEnter={this.onMouseEnterEvent}
                                                        eventMouseLeave={this.onMouseLeaveEvent}
                                                        eventTimeFormat={{
                                                            hour: 'numeric',
                                                            minute: '2-digit',
                                                            meridiem: false
                                                        }}
                                                    />
                                                </div>
                                            </SortableItem>
                                        )
                                    })}
                                </SortableList>
                            </div>

                        </div>
                    </div>
                </div>

                <Modal
                    status={this.modalStatusRep}
                    className="modal__add__booking abc__modal--top"
                >
                    {this.selectedEvent ?
                        <div className="abc__modal__content">
                            <div className="panel">
                                <Tabs
                                    items={
                                        [
                                            'Measure',
                                            'Fitter',
                                            'Logs',
                                            'Unavailability',
                                        ]
                                    }
                                    defaultIndex={this.getBookingDefaultTab()}
                                    showTab={[
                                        Access.isAllowed('EventTab', 'booking'),
                                        Access.isAllowed('EventTab', 'fitting'),
                                        true,
                                        true
                                    ]}
                                >
                                    <div>
                                        <BookingEvent
                                            calendarEvent={this.selectedEvent.fitting && this.selectedEvent.fitting.id ? this.selectedEvent.fitting.booking : this.selectedEvent.booking}
                                            // event={this.selectedEvent}
                                            auth={this.auth}
                                            user={this.selectedEvent.user}
                                            onLoading={this.onLoading}
                                            onChange={this.onChange}
                                            doDelete={this.deletingEvent}
                                        />
                                    </div>
                                    <div>
                                        <FittingEvent
                                            calendarEvent={this.selectedEvent.fitting}
                                            user={this.selectedEvent.user}
                                            onLoading={this.onLoading}
                                            onChange={this.onChange}
                                            doDelete={this.deletingEvent}
                                        />
                                    </div>
                                    <div>
                                        <div className="panel__body">
                                            <Logs
                                                // date={this.curDate}
                                                calendarEvent={this.selectedEvent}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="panel__body">
                                            <AvailabilityEvent
                                                date={this.curDate}
                                                user={this.selectedEvent.user}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                </Tabs>
                            </div>

                        </div>
                        : ''}

                </Modal>

                <Modal
                    status={this.modalMap}
                >
                    <div className="panel">
                        <WayPoints points={this.getUserPointAddresses()}/>
                    </div>
                </Modal>

                <Modal
                    status={this.modalDeleteConfirm}
                >

                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Delete event</div>
                        </div>
                        <div className="panel__body">
                            Are you sure you want to cancel this event?

                        </div>
                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={this.onDeleteEvent}>Yes</button>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        this.modalDeleteConfirm.show = false;
                                        this.setState({})
                                    }}
                                >No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    status={this.modalDeleteConfirm}
                >

                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Delete event</div>
                        </div>
                        <div className="panel__body">
                            Are you sure you want to cancel this event?

                        </div>
                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={this.onDeleteEvent}>Yes</button>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        this.modalDeleteConfirm.show = false;
                                        this.setState({})
                                    }}
                                >No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    status={this.modalOutsideConfirm}
                >
                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Alert Notice</div>
                        </div>

                        <div className="panel__body">
                            You are adding a booking outside the working hours. Please click "It's all good" to ignore
                            this message or move the booking to a different time frame.
                        </div>

                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={() => {
                                    this.modalOutsideConfirm.show = false;
                                    this.setState({})
                                }}>It's all good
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    status={this.modalRuns}
                    className="modal__printing"
                >

                    <Runs
                        date={this.curDate}
                        calendarType={this.calendarService.calendarType}
                        user={this.mapSelectedUser ? this.findUser(this.mapSelectedUser) : null}
                        events={this.getUserEventList()}
                    />

                </Modal>

                <Modal
                    status={this.modalPopup}
                    className="abc__modal--holding"
                    addedCloseFunc={this.showAsideCalendar}
                >
                    <div className="panel">
                        {Object.keys(this.workingTime).length > 0 &&
                            <HoldingCalendar
                                showRooms={this.calendarService.showRooms}
                                blinds={this.calendarService.blinds}
                                calendarType={this.calendarService.calendarType}
                                allReps={this.reps}
                                date={this.props.date}
                                onEventReceive={(userId, eventId) => this.eventReceive(userId, eventId)}
                                movedHoldingEvent={this.movedHoldingEvent}
                            />}
                    </div>
                </Modal>

                <ContextMenu
                    // target={'context-menu-content'}
                    ref={this.refContextMenu}
                    items={[
                        {
                            id: 'cut',
                            label: 'Cut',
                            onClick: () => {
                                this.onCopyEvent(true)
                            },
                        },
                        {
                            id: 'paste',
                            label: 'Paste',
                            onClick: this.onPasteEvent
                        },
                        {
                            id: 'delete',
                            label: 'Delete',
                            onClick: () => {
                                this.modalDeleteConfirm.show = true;
                                this.setState({})
                            }
                        },
                    ]}
                />

            </>
        );
    }
}