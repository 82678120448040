export class Auth {
    static storageKey = 'auth';

    userId: number = null;
    role: number = null;
    token: string = null;
    name: string = null;
    adminId: string = null;
    showrooms: [] = [];
    blinds: [] = [];
    startUrl: string = null;
    reportExclude: boolean = false;

    load() {
        const data = localStorage.getItem(Auth.storageKey);

        if (!data) {
            return;
        }

        let parsed = JSON.parse(data);

        this.userId = parsed.userId;
        this.role = parsed.role;
        this.token = parsed.token;
        this.name = parsed.name;
        this.adminId = parsed.adminId;
        this.showrooms = parsed.showrooms;
        this.blinds = parsed.blinds;
        this.startUrl = parsed.startUrl;
        this.reportExclude = parsed.reportExclude;
    }

    save() {
        localStorage.setItem(
            Auth.storageKey,
            JSON.stringify({
                userId: this.userId,
                role: this.role,
                token: this.token,
                name: this.name,
                showrooms: this.showrooms,
                blinds: this.blinds,
                adminId: this.adminId,
                startUrl: this.startUrl,
                reportExclude: this.reportExclude,
            })
        );
    }

    isAuthorized(): boolean {
        return this.userId && this.role && this.token;
    }

    isSelf(): boolean {
        return this.userId === this.adminId;
    }

    isInSession(): boolean {
        return sessionStorage.getItem('inSession') === '1';
    }

    setIsInSession(isInSession: boolean) {
        sessionStorage.setItem('inSession', isInSession ? '1' : '0');
    }
}
